import {FormikHelpers} from 'formik';

import {TextInput, TextInputProps} from './text-input';

export {Autocomplete} from './autocomplete';
export type {AutocompletePublicProps} from './autocomplete';
export {AutocompleteMultiple} from './autocomplete-multiple';
export type {AutocompleteMultiplePublicProps} from './autocomplete-multiple';
export {DatePicker} from './date-picker';
export {Form} from './form';
export {FormActions} from './form-actions';
export {FormErrors} from './form-errors';
export {MultiplierInput} from './multiplier-input';
export {NumberInput} from './number-input';
export {PercentageInput} from './percentage-input';
export {SubmitButton} from './submit-button';
export {TextInput} from './text-input';
export {Checkbox} from './checkbox';

// Alias old Input to new TextInput.
// TODO: Delete this file in future versions.
export const Input = TextInput;
export type InputProps = TextInputProps;

// TODO: Move this helper into each project because it's not generic enough to be in core.
// Had to redefine ErrorType here to not depend on graphql generated code.
type ErrorType = {
    field: string;
    messages: string[];
};

export const setErrors = (errors: ErrorType[] | undefined, actions: FormikHelpers<any>) => {
    errors?.forEach(error => {
        const messages = error.messages.join('\n');
        if (error.field.toLowerCase().includes('_all_')) {
            actions.setStatus({formErrors: messages});
        } else {
            actions.setFieldError(error.field, messages);
        }
    });
};

// Array helpers with typehint
export interface ArrayHelpers<T extends object = any> {
    /** Imperatively add a value to the end of an array */
    push: (obj: T) => void;
    /** Curried fn to add a value to the end of an array */
    handlePush: (obj: T) => () => void;
    /** Imperatively swap two values in an array */
    swap: (indexA: number, indexB: number) => void;
    /** Curried fn to swap two values in an array */
    handleSwap: (indexA: number, indexB: number) => () => void;
    /** Imperatively move an element in an array to another index */
    move: (from: number, to: number) => void;
    /** Imperatively move an element in an array to another index */
    handleMove: (from: number, to: number) => () => void;
    /** Imperatively insert an element at a given index into the array */
    insert: (index: number, value: T) => void;
    /** Curried fn to insert an element at a given index into the array */
    handleInsert: (index: number, value: T) => () => void;
    /** Imperatively replace a value at an index of an array  */
    replace: (index: number, value: T) => void;
    /** Curried fn to replace an element at a given index into the array */
    handleReplace: (index: number, value: T) => () => void;
    /** Imperatively add an element to the beginning of an array and return its length */
    unshift: (value: T) => number;
    /** Curried fn to add an element to the beginning of an array */
    handleUnshift: (value: T) => () => void;
    /** Curried fn to remove an element at an index of an array */
    handleRemove: (index: number) => () => void;
    /** Curried fn to remove a value from the end of the array */
    handlePop: () => () => void;
    /** Imperatively remove and element at an index of an array */
    remove(index: number): T | undefined;
    /** Imperatively remove and return value from the end of the array */
    pop(): T | undefined;
}