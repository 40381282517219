import {PureQueryOptions} from '@apollo/client'
import {t, Trans} from '@lingui/macro'
import {Button, Grid, Theme} from '@mui/material'
import {AsyncTable} from 'components/async-table'
import {CloseReasonAutocomplete} from 'components/autocompletes'
import {Form, FormActions, SubmitButton} from 'core/components/forms'
import {formatDate} from 'core/locale'
import {Formik, FormikErrors, FormikHelpers, setIn} from 'formik'
import {
    ExpiredQuoteFragment,
    GetExpiredQuotesDocument,
    QuoteCloseReasonInputType,
    useCloseQuotesMutation,
} from 'generated/graphql'
import {useSnackbar} from 'notistack'
import React, {FC, useMemo} from 'react'
import {makeStyles} from 'tss-react/mui'

interface ExpiredQuotesFormProps {
    quotes: ExpiredQuoteFragment[];
    onSubmit: () => void;
    onCancel: () => void;
    refetchQueries?: PureQueryOptions[];
}

interface CloseQuoteFormValues {closeReasons: QuoteCloseReasonInputType[];}

export const ExpiredQuotesForm: FC<React.PropsWithChildren<ExpiredQuotesFormProps>> = ({quotes, onSubmit, onCancel, refetchQueries = []}) => {
    const { classes } = useStyles();
    const [closeQuotes] = useCloseQuotesMutation();
    const {enqueueSnackbar} = useSnackbar();

    const initialValues: CloseQuoteFormValues = useMemo(() => {
        return {
            closeReasons: quotes.map(quote => ({
                quote: quote.id,
                description: '',
            }))
        };
    }, [quotes]);

    const validate = (values: CloseQuoteFormValues) => {
        let errors: FormikErrors<CloseQuoteFormValues> = {};
        values.closeReasons.forEach((reason, index) => {
            if (!reason || !reason.description || reason.description === '') {
                errors = setIn(errors, `closeReasons.${index}.description`, `Required field`);
            }
        });
        return errors;
    };
    const handleSubmit = async (values: CloseQuoteFormValues, {setSubmitting}: FormikHelpers<CloseQuoteFormValues>) => {
        setSubmitting(true);
        const {data} = await closeQuotes({
            variables: {input: values.closeReasons},
            refetchQueries: [
                {query: GetExpiredQuotesDocument},
                ...refetchQueries,
            ],
        });
        if (data?.closeQuotes?.status) {
            enqueueSnackbar(t`Quotes closed!`, {variant: 'success'});
        }
        setSubmitting(false);
        onSubmit();
    };
    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmit}>
            {props => {
                return (
                    <Form>
                        <Grid item={true} xs={12}>
                            <AsyncTable
                                id='expired-quotes'
                                data={quotes}
                                count={quotes.length}
                                disableToolbar={true}
                                fetchData={() => {}}
                                initialPageSize={100}
                                columns={[
                                    {key: 'displayId', label: t`ID`, ordering: true},
                                    {key: 'created', label: t`Date`, render: row => formatDate(row.created), ordering: true},
                                    {key: 'contact.account.name', label: t`Account`, ordering: true},
                                    {key: 'contact.email', label: t`Contact`, ordering: true},
                                    {key: 'createdBy.displayName', label: t`Created by`, ordering: true, orderingKey: 'createdBy.email'},
                                    {
                                        key: 'closeReason', label: t`Close reason`, render: (row, indexRow, indexCol) => {
                                            return <div className={classes.autocomplete}><CloseReasonAutocomplete name={`closeReasons.${indexRow}.description`} gridProps={{xs: 12}} /></div>;
                                        }
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <FormActions>
                                <Grid container={true} justifyContent='flex-end' spacing={2}>
                                    <Grid item={true}>
                                        <Button onClick={onCancel}><Trans>Cancel</Trans></Button>
                                    </Grid>
                                    <Grid item={true}>
                                        <SubmitButton label={t`Save`} submittingLabel={t`Saving...`} />
                                    </Grid>
                                </Grid>
                            </FormActions>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    autocomplete: {
        minWidth: '250px',
    }
}));
