import {Box, Button, FormControlLabel, FormGroup, Grid, GridProps, Popover, Switch} from '@mui/material';
import {AsyncTableProps} from 'components/async-table';
import {useUserColumns} from 'components/async-table/use-user-columns';
import {useToggle} from 'core/hooks';
import {FC, useRef} from 'react';

type ColumnSelectorProps<T> = Pick<AsyncTableProps<T>, 'id' | 'columns'> & Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>;

export const ColumnSelector = <T,>({
    id, columns, ...gridProps
}: ColumnSelectorProps<T>) => {
    const {open, toggle} = useToggle();
    const ref = useRef<HTMLButtonElement>(null);
    const {isColumnVisible, hideAll, showAll, toggleColumn} = useUserColumns({id, columns});

    if (!id) return null;

    return (
        <>
            <Grid item={true} {...gridProps}>
                <Button ref={ref} onClick={toggle} variant='contained' color='primary'>
                    Columnas
                </Button>
            </Grid>
            <Popover
                open={open}
                anchorEl={ref.current}
                onClose={toggle}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Box m={2}>
                    <FormGroup row={false}>
                        {columns.map(({key, label}) => {
                            if (!Boolean(key)) return null;
                            return (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isColumnVisible(key)}
                                            onChange={() => toggleColumn(key!)}
                                            name={key}
                                            color='primary'
                                        />
                                    }
                                    label={label}
                                />
                            );
                        })}
                    </FormGroup>
                    <Box display='flex' justifyContent='space-around'>
                        <Button onClick={showAll}>
                            Mostrar todas
                        </Button>
                        <Button onClick={hideAll}>
                            Ocultar todas
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};
