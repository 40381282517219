import {Grid, InputBaseProps, TextField, TextFieldProps} from '@mui/material';
import {Field, FieldProps} from 'formik';
import React, {FC, useMemo} from 'react';
import MaskedInput, {MaskedInputProps} from 'react-text-mask';

import {defaultGridProps, FormChild} from './form';

type BaseTextInputProps = {
    name: string;
    validate?: (value: string) => Promise<string | undefined> | string | void;
};

export type TextInputProps = FormChild
    & BaseTextInputProps
    & Partial<Pick<MaskedInputProps, 'mask'>>
    & Pick<TextFieldProps, 'label' | 'disabled' | 'placeholder' | 'onFocus' | 'InputProps' | 'multiline' | 'inputRef' | 'rows' | 'type' | 'inputMode' | 'size' | 'className' | 'classes' | 'required' | 'fullWidth' | 'InputLabelProps' | 'variant'>
    & Pick<InputBaseProps, 'startAdornment' | 'endAdornment'>;

interface MaskedInputWrapperProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

export const TextInput: FC<React.PropsWithChildren<TextInputProps>> = ({
    name,
    disabled,
    inputMode,
    startAdornment,
    endAdornment,
    mask,
    gridProps,
    fullWidth = true,
    required,
    validate,
    ...otherProps
}) => {
    const inputComponent: any = useMemo(() => {
        if (!mask) return 'input';
        const MaskedInputWrapper: FC<React.PropsWithChildren<MaskedInputWrapperProps>> = (props) => {
            return (
                <MaskedInput
                    {...props}
                    mask={mask}
                    guide={false}
                />
            );
        };
        return MaskedInputWrapper;
    }, [mask]);

    const InputProps: Partial<InputBaseProps> = {inputComponent};
    if (startAdornment) InputProps.startAdornment = startAdornment;
    if (endAdornment) InputProps.endAdornment = endAdornment;

    const component = (
        <Field name={name} validate={validate}>
            {({field, form, meta}: FieldProps) => (
                <TextField
                    variant='outlined'
                    fullWidth={fullWidth}
                    disabled={form.isSubmitting || disabled}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    inputProps={{inputMode}}
                    InputLabelProps={{required}}
                    {...field}
                    {...otherProps}
                    InputProps={{
                        ...otherProps.InputProps,
                        ...InputProps,
                    }}
                />
            )}
        </Field>
    );

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
};

TextInput.defaultProps = {
    gridProps: defaultGridProps,
};
