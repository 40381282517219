import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {EcnsEcnTaskNameChoices} from 'generated/graphql';
import {FC} from 'react';

export const TaskNameAutocomplete: FC<AutocompletePublicProps> = props => {
    const options = [
        {label: 'Nueva/Modificación/Cancelación de piezas ', value: EcnsEcnTaskNameChoices.NewModCancelParts},
        {label: 'Compras', value: EcnsEcnTaskNameChoices.Purchases},
        {label: 'Pricing', value: EcnsEcnTaskNameChoices.Pricing},
        {label: 'Forecast', value: EcnsEcnTaskNameChoices.Forecast},
        {label: 'Soporte de producto', value: EcnsEcnTaskNameChoices.ProductSupport},
        {label: 'Piezas arribadas', value: EcnsEcnTaskNameChoices.PartsArrival},
    ];

    return <Autocomplete
        options={options}
        valueKey='value'
        labelKey='label'
        {...props}
    />;
};
