import {t} from '@lingui/macro';
import {
    AttachMoneyRounded as AttachMoneyIcon,
    DescriptionRounded as DescriptionIcon,
    Home as HomeIcon,
    HomeWorkRounded as HomeWorkIcon,
    LocalShippingRounded as LocalShippingIcon,
    LocationCityRounded as LocationCityIcon,
    PaymentRounded as PaymentOutlinedIcon,
    PersonRounded as PersonIcon,
    SettingsRounded as SettingsIcon,
    StarRounded as StarIcon,
    TrendingUpRounded as TrendingUpIcon,
} from '@mui/icons-material';
import {List} from '@mui/material';
import {OrderTrackingIcon, PemIcon} from 'components/icons';
import {useUser} from 'contexts/user';
import React, {FC} from 'react';

import AppMenuLink from './app-menu-link';

interface AppMenuProps {
    onClose?(): void;
}

const AppMenu: FC<React.PropsWithChildren<AppMenuProps>> = ({onClose}) => {
    const {user} = useUser();

    return (
        <>
            <List component='nav' onClick={onClose}>
                {user?.organization?.group.isOem ? (
                    <>
                        <AppMenuLink href='/' label={t`Home`} icon={<HomeIcon />} />
                        <AppMenuLink href='/cotizador/oem-orders' label={t`OEM Orders`} icon={<HomeWorkIcon />} permission='viewOems' />
                        <AppMenuLink href='/cotizador/machine-start-ups' label={t`Machine start up`} icon={<PemIcon />} permission='viewMachineStartUps' />
                        <AppMenuLink href='/cotizador/supply-orders' label={t`Supply orders`} icon={<OrderTrackingIcon />} permission='viewSupplyOrders' />
                        {user?.permissions.manageShipments && <AppMenuLink href='/cotizador/order-tracking' label={t`Order tracking`} icon={<LocalShippingIcon />} permission='viewOrderTracking' />}
                        <AppMenuLink href='/cotizador/warranty-claims' label={t`Warranty Claim`} icon={<DescriptionIcon />} permission='viewWarrantyClaims' />
                        <AppMenuLink href='/cotizador/accounts' label={t`Accounts`} icon={<LocationCityIcon />} permission='viewAccounts' />
                        <AppMenuLink href='/cotizador/contacts' label={t`Contacts`} icon={<PersonIcon />} permission='viewContacts' />
                        {user?.isStaff && <AppMenuLink href='/cotizador/admin' label={t`Administration`} newTab={true} icon={<SettingsIcon />} />}
                    </>
                ) : (
                    <>
                        <AppMenuLink href='/' label={t`Home`} icon={<HomeIcon />} />
                        <AppMenuLink href='/cotizador/quotes' label={t`Quotes`} icon={<AttachMoneyIcon />} permission='viewQuotes' />
                        <AppMenuLink href='/cotizador/supply-orders' label={t`Supply orders`} icon={<OrderTrackingIcon />} permission='viewSupplyOrders' />
                        {user?.permissions.manageShipments && <AppMenuLink href='/cotizador/order-tracking' label={t`Order tracking`} icon={<LocalShippingIcon />} permission='viewOrderTracking' />}
                        <AppMenuLink href='/cotizador/sales' label={t`Sales`} icon={<PaymentOutlinedIcon />} permission='viewSales' />
                        <AppMenuLink href='/cotizador/oem-orders' label={t`OEM Orders`} icon={<HomeWorkIcon />} permission='viewOems' />
                        <AppMenuLink href='/cotizador/accounts' label={t`Accounts`} icon={<LocationCityIcon />} permission='viewAccounts' />
                        <AppMenuLink href='/cotizador/contacts' label={t`Contacts`} icon={<PersonIcon />} permission='viewContacts' />
                        <AppMenuLink href='/cotizador/opportunities' label={t`Opportunities`} icon={<StarIcon />} permission='viewOpportunities' />
                        <AppMenuLink href='/cotizador/analytics' label={t`Statistics`} icon={<TrendingUpIcon />} permission='viewAnalytics' />
                        <AppMenuLink href='/cotizador/machine-start-ups' label={t`Machine start up`} icon={<PemIcon />} permission='viewMachineStartUps' />
                        <AppMenuLink href='/cotizador/warranty-claims' label={t`Warranty Claim`} icon={<DescriptionIcon />} permission='viewWarrantyClaims' />
                        {user?.isStaff && <AppMenuLink href='/admin' label={t`Administration`} newTab={true} icon={<SettingsIcon />} />}
                    </>
                )}
            </List>
        </>
    );
};

export default AppMenu;

