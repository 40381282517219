import {Button, ButtonProps} from '@mui/material';
import {useFormikContext} from 'formik';
import {FC} from 'react';

interface SubmitButtonProps extends Pick<ButtonProps, 'size' | 'disabled'> {
    label?: string;
    submittingLabel: string;
    fullWidth?: boolean;
}

export const SubmitButton: FC<React.PropsWithChildren<SubmitButtonProps>> = ({label, submittingLabel, fullWidth, children, ...rest}) => {
    const {isSubmitting, isValid} = useFormikContext();
    return (
        <Button
            {...rest}
            type='submit'
            color='primary'
            variant='contained'
            disabled={isSubmitting}
            fullWidth={fullWidth}>
            {isSubmitting ? submittingLabel : (label || children)}
        </Button>
    );
};
