import {t, Trans} from '@lingui/macro'
import {Button} from '@mui/material'
import {AccountDocumentationFilesInput} from 'components/accounts/account-documentation-files-input'
import {AccountRegionAutocomplete, ChildOrganizationAutocomplete} from 'components/autocompletes'
import {SearchLocationAutocomplete} from 'components/autocompletes/locations-autocompletes'
import {useUser} from 'contexts/user'
import {Form, FormActions, FormErrors, SubmitButton, TextInput} from 'core/components/forms'
import {Formik, FormikHelpers} from 'formik'
import {CreateAccountMutationInput, UpdateAccountMutationInput} from 'generated/graphql'
import React, {FC} from 'react'
import * as settings from 'settings'
import {useGetTaxIdLabel} from 'utils'

type Values = (Omit<CreateAccountMutationInput, 'files'> | Omit<UpdateAccountMutationInput, 'files'>) & {files: File[];};
export type AccountFormValues = Values;

export interface AccountFormProps {
    initialValues?: Partial<Values>;
    onSubmit(values: Values, actions: FormikHelpers<Values>): void;
    onCancel?(): void;
    edit?: boolean;
}

export const AccountForm: FC<React.PropsWithChildren<AccountFormProps>> = ({initialValues, onSubmit, onCancel, edit}) => {
    const defaultInitialValues: Values = {
        name: '',
        taxId: '',
        region: '',
        owner: '',
        location: '',
        files: [],
        extraLocation: undefined,
    };
    const {user} = useUser();
    const {assignAccountOwner} = user?.permissions || {};
    const taxId = useGetTaxIdLabel();

    return (
        <Formik onSubmit={onSubmit} initialValues={{...defaultInitialValues, ...(initialValues || {})}}>
            <Form withBackdrop>
                <TextInput name='name' label={t`Business name`} />
                <TextInput name='taxId' label={taxId} mask={settings.TAX_ID_MASK} />
                <AccountRegionAutocomplete name='region' />
                <SearchLocationAutocomplete name='location' />
                <TextInput name='extraLocation' label='Información extra de ubicación' gridProps={{xs: 12}} />
                {assignAccountOwner && <ChildOrganizationAutocomplete name='owner' />}
                {!edit && <AccountDocumentationFilesInput name='files' />}
                <FormActions>
                    <SubmitButton label={t`Save`} submittingLabel={t`Saving...`} />
                    {onCancel && <Button onClick={onCancel}><Trans>Cancel</Trans></Button>}
                    <FormErrors />
                </FormActions>
            </Form>
        </Formik>
    );
};
