import {Handyman, Home, PrecisionManufacturing, TrendingUp} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import Link from 'next/link';
import {FC} from 'react';

export const PartsManagementMenu: FC = () => {
    return (
        <Box marginLeft={2} display='flex' flexGrow={1} columnGap={1}>
            <Link href='/' legacyBehavior passHref>
                <Button
                    startIcon={<Home />}
                    color='primary'>
                    Home
                </Button>
            </Link>
            <Link href='/parts-management/' legacyBehavior passHref>
                <Button startIcon={<Handyman />} color='primary'>
                    ECNs
                </Button>
            </Link>
            <Link href='/parts-management/material-master' legacyBehavior passHref>
                <Button startIcon={<PrecisionManufacturing />} color='primary'>
                    Material master
                </Button>
            </Link>
            <Link href='/parts-management/analytics' legacyBehavior passHref>
                <Button startIcon={<TrendingUp />} color='primary'>
                    Estadisticas
                </Button>
            </Link>
        </Box>
    );
};
