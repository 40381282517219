import {Autocomplete, AutocompletePublicProps} from 'core/components/forms'
import {SupplyOrdersShipmentStatusChoices} from 'generated/graphql'
import {FC} from 'react'

export const ShipmentStatusAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => {
    const options = [
        {value: SupplyOrdersShipmentStatusChoices.Confirmed.toLowerCase(), label: 'Confirmado'},
        {value: SupplyOrdersShipmentStatusChoices.Cancelled.toLowerCase(), label: 'Cancelado'},
        {value: SupplyOrdersShipmentStatusChoices.OnHold.toLowerCase(), label: 'Retenido'},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='label'
            label='Estado'
            size='small'
            {...props}
        />
    );
};
