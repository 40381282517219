import {t} from '@lingui/macro'
import {Autocomplete, AutocompletePublicProps} from 'core/components/forms'
import {QuotesQuoteStatusChoices} from 'generated/graphql'
import {FC} from 'react'

export const QuoteStatusAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const options = [
        {value: QuotesQuoteStatusChoices.InProgress.toLowerCase(), label: t`In progress`},
        {value: QuotesQuoteStatusChoices.InSale.toLowerCase(), label: t`In sale`},
        {value: QuotesQuoteStatusChoices.InSupplyOrder.toLowerCase(), label: t`In supply order`},
        {value: QuotesQuoteStatusChoices.Closed.toLowerCase(), label: t`Closed`},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='label'
            label={t`Status`}
            {...props}
        />
    );
};
