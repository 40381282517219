import {Alert, Grid} from '@mui/material';
import {useFormikContext} from 'formik';
import {FC} from 'react';

export const FormErrors: FC = () => {
    const {status} = useFormikContext();

    if (!status?.formErrors) return null;

    return (
        <Grid item={true} xs={12}>
            <Alert severity='error'>
                {status.formErrors}
            </Alert>
        </Grid>
    );
};
