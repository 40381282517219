import {t} from '@lingui/macro'
import {Autocomplete, AutocompletePublicProps, TextInput} from 'core/components/forms'
import {useFormikContext} from 'formik'
import {WarrantyClaimInspectionFormValues} from 'forms/warranty-claim/warranty-claim-inspection-form'
import {useGetFailuresQuery} from 'generated/graphql'
import {FC, useEffect} from 'react'

export const FailureAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps> & {othersName: string;}> = ({othersName, ...props}) => {
    const {data, loading} = useGetFailuresQuery();
    const {values, setFieldValue} = useFormikContext<WarrantyClaimInspectionFormValues>();

    useEffect(() => {
        if (values.failureDescription) {
            setFieldValue('others', '');
        }
    }, [values.failureDescription]);

    useEffect(() => {
        if (values.others) {
            setFieldValue('failureDescription', '');
        }
    }, [values.others]);

    return (
        <>
            <Autocomplete
                options={data?.failures || []}
                loading={loading}
                valueKey='description'
                labelKey='description'
                label={t`Failure`}
                gridProps={{xs: 12}}
                {...props}
            />
            <TextInput name={othersName} multiline={true} rows={3} label={t`Other`} gridProps={{xs: 12}} />
        </>
    );
};
