import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {EcnsEcnStatusChoices} from 'generated/graphql';
import {FC} from 'react';

export const EcnStatusAutocomplete: FC<AutocompletePublicProps> = props => {
    const options = [
        {value: EcnsEcnStatusChoices.InProgress, label: 'En progreso'},
        {value: EcnsEcnStatusChoices.Finished, label: 'Finalizada'},
        {value: EcnsEcnStatusChoices.Effective, label: 'Efectivo'},
        {value: EcnsEcnStatusChoices.Cancelled, label: 'Cancelado'},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='label'
            label='Estado'
            {...props}
        />
    );
};