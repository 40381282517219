import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms/autocomplete-multiple';
import {useGetEcnsNumbersQuery} from 'generated/graphql';
import {FC} from 'react';

export const EcnNumbersAutocompleteMultiple: FC<AutocompleteMultiplePublicProps> = props => {
    const {data, loading} = useGetEcnsNumbersQuery({fetchPolicy: 'cache-and-network'});

    return (
        <AutocompleteMultiple
            options={data?.ecns?.items.map(ecn => ({value: ecn.ecnNumber})) || []}
            loading={loading}
            valueKey={'value'}
            labelKey={'value'}
            {...props}
        />
    );
};

export const EcnNumbersAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data, loading} = useGetEcnsNumbersQuery({fetchPolicy: 'cache-and-network'});

    return (
        <Autocomplete
            options={data?.ecns.items.map(ecn => ({value: ecn.ecnNumber})) || []}
            loading={loading}
            valueKey={'value'}
            labelKey={'value'}
            {...props}
        />
    );
};
