import {SvgIconProps} from '@mui/material';
import {EcnsEcnSeasonPriorityChoices} from 'generated/graphql';
import getConfig from 'next/config';

export const SITE_NAME = 'Precision Planting';

export const LOGIN_URL = '/users/login';
export const LOGOUT_URL = '/users/logout';

const {publicRuntimeConfig} = getConfig();

export const LANGUAGE_CODE = publicRuntimeConfig.languageCode;
export const LANGUAGES = [
    {code: 'es-ar', name: 'Español'},
    {code: 'en-us', name: 'English'},
];
export const ENVIRONMENT = publicRuntimeConfig.environment;

export const CURRENCY_CODE = publicRuntimeConfig.currencyCode;

export const TIMEZONE = -3;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const MAX_DECIMALS = publicRuntimeConfig.maxDecimals;

export const TAX_ID_MASK = () => {
    return [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
};

export const PRIORITY_COLORS: {[key in EcnsEcnSeasonPriorityChoices]: SvgIconProps['color']} = {
    [EcnsEcnSeasonPriorityChoices.Low]: 'success',
    [EcnsEcnSeasonPriorityChoices.Medium]: 'warning',
    [EcnsEcnSeasonPriorityChoices.High]: 'error',
};

