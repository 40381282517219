import {Grid, InputLabelProps} from '@mui/material';
import {DatePicker as MUIDatePicker, DatePickerProps as MUIDatePickerProps} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TextInputProps} from 'core/components/forms/text-input';
import dayjs from 'dayjs';
import {useField, useFormikContext} from 'formik';
import React, {FC} from 'react';
import * as settings from 'settings';

import {defaultGridProps, FormChild} from './form';

type BaseDatePickerProps = {
    name: string;
    submitOnSelect?: boolean;
    showTodayButton?: boolean;
};

type DatePickerProps = FormChild
    & BaseDatePickerProps
    & Partial<Pick<MUIDatePickerProps<unknown>, 'className' | 'label' | 'disabled'>>
    & Partial<Pick<TextInputProps, 'className' | 'size' | 'InputProps'>>
    & Pick<InputLabelProps, 'shrink'>;

export const DatePicker: FC<React.PropsWithChildren<DatePickerProps>> = ({
    name,
    disabled,
    gridProps,
    submitOnSelect,
    showTodayButton,
    shrink,
    InputProps,
    ...otherProps
}) => {
    const {isSubmitting, setFieldValue, submitForm} = useFormikContext();
    const [field, meta] = useField<string>(name);

    if (field.value && field.value.length !== 10) {
        console.warn(`DatePicker value must be a date only ISO-8061 string (YYYY-MM-DD), received: ${field.value}`);
    }

    const component = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MUIDatePicker<dayjs.Dayjs>
                disabled={isSubmitting || disabled}
                format={settings.DATE_FORMAT}
                value={field.value && dayjs(field.value) || null}
                slotProps={{
                    textField: {
                        size: otherProps.size,
                        fullWidth: true,
                        variant: 'outlined',
                        onBlur: field.onBlur,
                        error: meta.touched && Boolean(meta.error),
                        helperText: meta.touched && meta.error,
                        InputLabelProps: {shrink},
                        InputProps,
                    }
                }}
                onChange={(date) => {
                    const value = date ? date.format('YYYY-MM-DD') : '';
                    setFieldValue(name, value, true);
                    if (submitOnSelect) submitForm();
                }}
                {...otherProps}
            />
        </LocalizationProvider>
    );

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
};

DatePicker.defaultProps = {
    gridProps: defaultGridProps,
};
