import * as Sentry from '@sentry/node';
import {FC, useEffect} from 'react';
import {useUser} from 'contexts/user';

export const SetSentryUser: FC = () => {
    const {user} = useUser()
    useEffect(() => {
        if (user) {
            Sentry.setUser({
                id: user?.id,
                email: user?.email,
            });
        } else {
            Sentry.setUser(null);
        }
    }, [user]);
    return null;
};
