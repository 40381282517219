import {t} from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import {CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from '@mui/material';
import {GraphQLErrors} from 'components/graphql-errors';
import {setErrors} from 'core/components/forms';
import {FormikHelpers, useFormikContext} from 'formik';
import {AccountForm, AccountFormProps, AccountFormValues} from 'forms/accounts/account-form';
import {GetAccountOptionsDocument, useCreateAccountMutation, useGetEditAccountQuery} from 'generated/graphql';
import {useSnackbar} from 'notistack';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

type AccountDialogFormProps = {
    open: boolean;
    accountFieldName?: string;
    accountId?: string;
    toggle: () => void;
} & Pick<AccountFormProps, 'edit' | 'initialValues'>;

export const AccountDialogForm: FC<React.PropsWithChildren<AccountDialogFormProps>> = ({
    open,
    toggle,
    accountId,
    edit,
    accountFieldName = 'account',
    initialValues,
}) => {
    const {classes} = useStyles();
    const [createAccount, {error: mutationError}] = useCreateAccountMutation();
    const {data: accountData, loading, error: queryError, refetch} = useGetEditAccountQuery({
        fetchPolicy: 'network-only',
        variables: {id: accountId},
        skip: !Boolean(accountId),
    });
    const {enqueueSnackbar} = useSnackbar();
    const {setFieldValue} = useFormikContext();

    const handleSubmit = async (
        {files, ...values}: AccountFormValues,
        actions: FormikHelpers<AccountFormValues>,
    ) => {
        setFieldValue(accountFieldName, '');
        const {data} = await createAccount({
            variables: {input: {...values, files: files.map(file => ({file}))}},
            awaitRefetchQueries: true,
            refetchQueries: [
                {query: GetAccountOptionsDocument},
                {query: GetAccountOptionsDocument, ...(Boolean(values.owner) && {variables: {options: {owner: [values.owner]}}} || {})}
            ],
        });
        if (data?.createAccount?.errors?.length === 0 && data.createAccount.account) {
            setFieldValue(accountFieldName, data.createAccount.account?.id, true);
            enqueueSnackbar(t`Account created!`, {variant: 'success'});
            toggle();
        } else {
            setErrors(data?.createAccount?.errors, actions);
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            fullWidth={true}
            onClose={toggle}
            keepMounted={false}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container={true} justifyContent='flex-end'>
                    <IconButton onClick={toggle} size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.dialogContent} item={true} xs={12}>
                    {queryError && <GraphQLErrors error={queryError} refetch={refetch} />}
                    {mutationError && <GraphQLErrors error={mutationError} />}
                    {loading && <Grid container justifyContent='center'><CircularProgress color='primary' /></Grid>}
                    {!loading && open && (
                        <AccountForm
                            onSubmit={handleSubmit}
                            onCancel={toggle}
                            edit={edit}
                            initialValues={accountData && edit && {
                                id: accountData.account.id,
                                name: accountData.account.name,
                                externalId: accountData.account.externalId,
                                taxId: accountData.account.taxId,
                                location: accountData.account.location?.id,
                                extraLocation: accountData.account.extraLocation,
                                owner: accountData.account.owner?.id,
                                region: accountData.account.region?.id,
                            } || initialValues}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: 0,
    },

    dialogContent: {
        padding: theme.spacing(1),
    }
}));
