import {t} from '@lingui/macro'
import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms'
import {ProductFragment, useGetProductsQuery} from 'generated/graphql'
import {FC} from 'react'

export const ProductsAutocompleteMultiple: FC<React.PropsWithChildren<AutocompleteMultiplePublicProps>> = props => {
    const {data: {products = [] as ProductFragment[]} = {}, loading} = useGetProductsQuery();

    return (
        <AutocompleteMultiple
            options={products.map(({externalId, displayName}) => ({externalId, displayName})) || []}
            loading={loading}
            valueKey='externalId'
            labelKey='displayName'
            label={t`Products`}
            {...props}
        />
    );
};