import {AsyncTableProps} from 'components/async-table';
import {useLocalStorage} from 'core/hooks';
import {useCallback, useEffect, useMemo} from 'react';
import {notEmpty} from 'utils';

type UseUserColumns = {} & Pick<AsyncTableProps<any>, 'id' | 'columns'>;

type TableColumns = {
    hidden: string[];
    visible: string[];
};

type UserConfigMap = {
    [key: string]: TableColumns;
};

/** Guard to validate the shape, if not valid, it will show all columns again, preventing sync problems */
const validateShape = (tableColumns: TableColumns | any): tableColumns is TableColumns => {
    return 'hidden' in tableColumns && 'visible' in tableColumns && Array.isArray(tableColumns.hidden) && Array.isArray(tableColumns.visible);
};

export const useUserColumns = ({id, columns}: UseUserColumns) => {
    const [userColumns, setUserColumns] = useLocalStorage<UserConfigMap>('user-columns', {});
    const visibleColumns = useMemo(() => {
        return id && userColumns[id]?.visible && new Set(userColumns[id]?.visible) || new Set<string>();
    }, [userColumns, id]);
    const hiddenColumns = useMemo(() => {
        return id && userColumns[id]?.hidden && new Set(userColumns[id]?.hidden) || new Set<string>();
    }, [userColumns, id]);

    useEffect(() => {
        if (id && (!(id in userColumns) || !validateShape(userColumns[id])))
            // Generates the initial config for this id
            setUserColumns((prev) => ({
                ...prev,
                [id]: {
                    visible: columns.map(({key}) => key).filter(notEmpty),
                    hidden: [],
                }
            }));
        else if (id && userColumns[id]) {
            // The columns that aren't hidden nor visible, will be added as visible
            const savedColumns = new Set(...Array.from(hiddenColumns), ...Array.from(visibleColumns));
            const columnsKeys = columns.map(({key}) => key).filter(notEmpty);
            if (!columnsKeys.every(k => savedColumns.has(k))) {
                const keysToAdd = columnsKeys.filter(k => !savedColumns.has(k));
                setUserColumns(prev => ({
                    ...prev,
                    [id]: {
                        visible: [...keysToAdd, ...Array.from(visibleColumns)],
                        hidden: Array.from(hiddenColumns),
                    },
                }));
            }
        }
    }, []);

    const isColumnVisible = useCallback(
        (columnKey?: string): boolean =>
            (columnKey && visibleColumns.has(columnKey)) || columnKey === undefined || columnKey === '' || !id,
        [visibleColumns],
    );

    const toggleColumn = useCallback(
        (columnKey: string) => {
            if (!id) return;
            setUserColumns((prev) => {
                if (isColumnVisible(columnKey)) {
                    visibleColumns.delete(columnKey);
                    return ({
                        ...prev,
                        [id]: {
                            visible: Array.from(visibleColumns),
                            hidden: Array.from(hiddenColumns.add(columnKey)),
                        },
                    });
                }
                hiddenColumns.delete(columnKey);
                return ({
                    ...prev,
                    [id]: {
                        visible: Array.from(visibleColumns.add(columnKey)),
                        hidden: Array.from(hiddenColumns),
                    },
                });
            });
        },
        [isColumnVisible, hiddenColumns, visibleColumns, setUserColumns],
    );

    const hideAll = useCallback(() => id && setUserColumns((prev) => {
        return ({
            ...prev,
            [id]: {
                visible: [],
                hidden: columns.map(({key}) => key).filter(notEmpty),
            },
        });
    }), [setUserColumns, columns]);
    const showAll = useCallback(() => {
        return id &&
            setUserColumns((prev) => ({
                ...prev,
                [id]: {
                    visible: columns.map(({key}) => key).filter(notEmpty),
                    hidden: [],
                },
            })
            );
    }, [setUserColumns, columns]);

    return {visibleColumns, hiddenColumns, toggleColumn, isColumnVisible, hideAll, showAll};
};
