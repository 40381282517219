import {Menu, MenuItem, MenuProps} from '@mui/material'
import React, {FC, useRef} from 'react'

type Option = {
    label: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
};

type DropdownProps = {
    open: boolean;
    onClose: () => void;
    menuProps?: Omit<MenuProps, 'anchorEl' | 'keepMounted' | 'open' | 'onClose'>;
    options: Option[];
};

export const Dropdown: FC<React.PropsWithChildren<DropdownProps>> = ({open, onClose, menuProps, options, children}) => {
    const target = useRef<HTMLElement>(null);
    const onClickWrapper = (func: () => void) => () => {
        onClose();
        func();
    };

    if (!children) throw new Error('Must define children');
    return (
        <>
            <span ref={target} >{children}</span>
            <Menu
                anchorEl={target.current}
                keepMounted
                open={open}
                onClose={onClose}
                {...menuProps}
            >
                {options.map(({label, onClick, disabled}) => <MenuItem disabled={disabled} onClick={onClickWrapper(onClick)}>{label}</MenuItem>)}
            </Menu>
        </>
    );
};