import {
    CircularProgress,
    createFilterOptions,
    Grid,
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
    TextField,
} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import React, {useMemo} from 'react';

import {defaultGridProps, FormChild} from './form';

type BaseAutocompleteProps<T> = {
    valueKey: keyof T;
    labelKey: keyof T;
};

export type AutocompletePublicProps<T = any> = FormChild & {
    name: string;
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    submitOnSelect?: boolean;
    disableClearable?: boolean;
    endAdorment?: React.ReactNode;
    required?: boolean;
} & Pick<
        MUIAutocompleteProps<T, false, false, false>,
        'size' | 'sx' | 'renderOption' | 'getOptionLabel' | 'placeholder'
    >;

export type AutocompleteProps<T> = FormChild &
    BaseAutocompleteProps<T> &
    AutocompletePublicProps<T> &
    Pick<MUIAutocompleteProps<T, false, false, false>, 'options' | 'size'> & {defaultValue?: T};

export function Autocomplete<T extends {[key: string]: string | boolean | number | object | undefined}>(
    props: AutocompleteProps<T>
) {
    const {
        name,
        label,
        disabled,
        loading,
        submitOnSelect,
        options,
        disableClearable,
        valueKey,
        labelKey,
        size,
        gridProps,
        defaultValue,
        endAdorment,
        renderOption,
        getOptionLabel,
        sx,
        required,
    } = props;

    const {isSubmitting, setFieldValue, submitForm} = useFormikContext();
    const [field, meta] = useField<string>(name);
    const value = options.find(option => option[valueKey] === field.value) || null;

    // All props will trigger a re-render.
    const memoDeps = Object.keys(props)
        .sort()
        .map(key => (props as any)[key])
        .concat([value, isSubmitting]);

    const component = useMemo(
        () => (
            <MUIAutocomplete<T, false, typeof disableClearable, false>
                value={value}
                options={options}
                getOptionLabel={getOptionLabel ? getOptionLabel : option => option[labelKey] as any}
                isOptionEqualToValue={(option, selected) => option[valueKey] === selected[valueKey]}
                multiple={false}
                blurOnSelect={true}
                filterOptions={createFilterOptions({stringify: option => option[labelKey]!.toString()})}
                openOnFocus={true}
                disabled={isSubmitting || disabled}
                noOptionsText={loading ? <CircularProgress size={20} /> : 'No hay opciones'}
                loading={loading}
                size={size}
                sx={sx}
                renderOption={renderOption}
                defaultValue={defaultValue}
                onChange={(_event: any, option: T | null) => {
                    // When this issue is merged we can pass shouldValidate
                    // directly to helpers.setValue and helpers.setTouched.
                    // https://github.com/jaredpalmer/formik/pull/2371
                    setFieldValue(name, option ? option[valueKey] : '', true);
                    if (submitOnSelect) submitForm();
                }}
                onBlur={() => {
                    // FIXME: The error is persisted and you have to trigger the blur or change event again
                    // to actually clear the previous error.
                    // helpers.setTouched(true, true);
                    // setFieldTouched(name, true, true);
                }}
                renderInput={params => (
                    <TextField
                        variant='outlined'
                        label={label}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                        {...params}
                        InputLabelProps={{...params.InputLabelProps, required}}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading && <CircularProgress size={20} />}
                                    {params.InputProps.endAdornment}
                                    {endAdorment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        ),
        memoDeps
    );

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
}

Autocomplete.defaultProps = {
    gridProps: defaultGridProps,
};

// const isSingleOption = (option: T | T[]): option is T => !Array.isArray(option);
// const isMultipleOption = (option: T | T[]): option is T[] => Array.isArray(option);

// if (!option) {
//     setFieldValue(name, '', true);
// } else if (isMultipleOption(option)) {
//     setFieldValue(name, option.map(option => option[valueKey]), true);
// } else if (isSingleOption(option)) {
//     setFieldValue(name, option[valueKey], true);
// }
