import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {GetProductSubcategoriesQuery, useGetProductSubcategoriesQuery} from 'generated/graphql';
import {FC} from 'react';

export const ProductSubcategoryAutocomplete: FC<AutocompletePublicProps & {
    filter?: (value: GetProductSubcategoriesQuery['productSubcategories'][number]) => boolean;
}> = ({filter, ...props}) => {
    const {data: {productSubcategories} = {}, loading} = useGetProductSubcategoriesQuery();
    const options = filter ? productSubcategories?.filter(filter) : productSubcategories;
    return (
        <Autocomplete
            options={options?.map(({id, name}) => ({id, name})) || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label='Subcategoría'
            {...props}
        />
    );
};
