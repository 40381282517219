import 'dayjs/locale/es';
import 'dayjs/locale/pt';

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import numbro from 'numbro';
import * as settings from 'settings';

// Date & Time

dayjs.locale(settings.LANGUAGE_CODE.split('-')[0]);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(weekOfYear);

export const formatTimestamp = (date: dayjs.Dayjs | string | undefined | null, format: string) => {
    if (!date) return '';
    return dayjs(date).utcOffset(settings.TIMEZONE).format(format);
};

export const formatDate = (date?: dayjs.Dayjs | string) => formatTimestamp(date, settings.DATE_FORMAT);
export const formatDateTime = (date?: dayjs.Dayjs | string) => formatTimestamp(date, settings.DATETIME_FORMAT);
export const formatTime = (date?: dayjs.Dayjs | string) => formatTimestamp(date, settings.TIME_FORMAT);

// Numbers

settings.LANGUAGES.forEach(({code}) => {
    // Country code must be uppercase to match numbro's filenames.
    const [language, country] = code.split('-');
    code = `${language}-${country.toUpperCase()}`;

    // Skip numbro's default language.
    if (language === 'en') return;

    numbro.registerLanguage(require(`numbro/languages/${code}`));
});

numbro.setLanguage(settings.LANGUAGE_CODE);
numbro.setDefaults({
    mantissa: Number(settings.MAX_DECIMALS),
    thousandSeparated: true,
});

export const formatNumber = (number: number): string => {
    return numbro(number).format({
        trimMantissa: true,
    });
};

export const formatPercentage = (number: number): string => {
    return numbro(number).format({
        output: 'percent',
        trimMantissa: true,
        spaceSeparated: true,
    });
};

export const formatCurrency = (number: number, options: {decimals?: number;} = {}, numbroOptions?: numbro.Format): string => {
    const result = numbro(number).format({
        output: 'currency',
        currencySymbol: settings.CURRENCY_CODE,
        spaceSeparated: true,
        ...numbroOptions,
        mantissa: options.decimals === undefined ? Number(settings.MAX_DECIMALS) : options.decimals,
    });
    // Use a non-breaking space so currency code doesn't wrap to another line.
    // https://en.wikipedia.org/wiki/Non-breaking_space
    return result.replace(' ', '\u00A0');
};
