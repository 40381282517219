import {ApolloClient, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';

/**
 * GitHub Issue https://github.com/jaydenseric/apollo-upload-client/issues/88
 * This what you can track the upload progress on multipart forms
 *  */
const parseHeaders = (rawHeaders: string) => {
    const headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
        const parts = line.split(':');
        const key = parts.shift().trim();
        if (key) {
            const value = parts.join(':').trim();
            headers.append(key, value);
        }
    });
    return headers;
};

type Options = RequestInit & {
    useUpload?: boolean;
    method: string;
    headers: {[key: string]: string;};
    onProgress: () => void;
    onAbortPossible?: (f: () => void) => void;
    body: XMLHttpRequestBodyInit | Document | null | undefined;
};

export const uploadFetch = (url: URL, options: Options) =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const opts: any = {
                status: xhr.status,
                statusText: xhr.statusText,
                headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
            };
            opts.url =
                'responseURL' in xhr
                    ? xhr.responseURL
                    : opts.headers.get('X-Request-URL');
            const body =
                'response' in xhr ? xhr.response : (xhr as any).responseText;
            resolve(new Response(body, opts));
        };
        xhr.onerror = (e) => {
            console.error(e);
            reject(new TypeError('Network request failed'));
        };
        xhr.ontimeout = (e) => {
            console.error(e);
            reject(new TypeError('Network request failed'));
        };
        xhr.open(options.method, url, true);

        Object.keys(options.headers).forEach((key) => {
            xhr.setRequestHeader(key, options.headers[key]);
        });

        if (xhr.upload) {
            xhr.upload.onprogress = options.onProgress;
        }

        options.onAbortPossible?.(() => {
            xhr.abort();
        });

        xhr.send(options.body);
    });

const customFetch = (uri: RequestInfo | URL, options?: Options) => {
    if (options?.useUpload) {
        return uploadFetch(uri as URL, options);
    }
    return fetch(uri as RequestInfo, options);
};

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: '/graphql/',
        credentials: 'include',
        fetch: customFetch as any,
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-first',
        },
    },
});
