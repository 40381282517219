import {t, Trans} from '@lingui/macro';
import {Add as AddIcon, Edit as EditIcon, MoreVert} from '@mui/icons-material';
import {Autocomplete as MUIAutocomplete} from '@mui/lab';
import {createFilterOptions, Grid, IconButton, MenuItem, TextField} from '@mui/material';
import {useUser} from 'contexts/user';
import {Dropdown} from 'core/components/dropdowns/dropdown';
import {
    Autocomplete,
    AutocompleteMultiple,
    AutocompleteMultiplePublicProps,
    AutocompletePublicProps,
} from 'core/components/forms';
import {useToggle} from 'core/hooks';
import {usePrevious} from 'core/hooks/use-previous';
import {Field, FieldProps, Formik, useField, useFormikContext} from 'formik';
import {AccountDialogForm} from 'forms/accounts/account-dialog-form';
import {ContactDialogForm} from 'forms/contacts/contact-dialog-form';
import {SeederDialogForm} from 'forms/seeders/seeder-dialog-form';
import {
    AccountOptionFragment,
    GetChildOrganizationsQuery,
    GetContactOptionsDocument,
    GetContactOptionsQueryVariables,
    GetOemProductsQuery,
    MachineStartUpsMachineStartUpFactoryStepChoices,
    MachineStartUpsMachineStartUpStepChoices,
    OemsShipmentOemStatusChoices,
    OrganizationType,
    ProductFragment,
    ProductsProductWarrantyClaimReviewStatusChoices,
    QuotesQuoteBillingTypeChoices,
    useGetAccountOptionQuery,
    useGetAccountOptionsQuery,
    useGetAccountRegionsQuery,
    useGetChildOrganizationsQuery,
    useGetChildUsersQuery,
    useGetContactAccountLazyQuery,
    useGetContactOptionsLazyQuery,
    useGetContactOptionsQuery,
    useGetContactTypesQuery,
    useGetEditAccountLazyQuery,
    useGetFactoryControlOrganizationsQuery,
    useGetLocationLazyQuery,
    useGetMissingProductsQuery,
    useGetOpportunityChannelsQuery,
    useGetOpportunityReasonsQuery,
    useGetOpportunitySituationsQuery,
    useGetOrganizationsQuery,
    useGetPaymentMethodCategoriesQuery,
    useGetPaymentMethodOptionsLazyQuery,
    useGetPaymentMethodOptionsQuery,
    useGetProductCategoriesQuery,
    useGetProductOptionsQuery,
    useGetSeederBrandsLazyQuery,
    useGetSeederBrandsQuery,
    useGetSeederModelsLazyQuery,
    useGetSeederOptionsQuery,
    useGetUsersQuery,
    useGetWarrantyClaimsIdsQuery,
    UserType,
} from 'generated/graphql';
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';

export {SupplyStateAutocomplete, SupplyStateAutocompleteMultiple} from './supply-state-autocomplete';
export {CloseReasonAutocomplete} from './close-reason-autocomplete';
export {FailureAutocomplete} from './failure-autocomplete';
export {InpectionStateAutocomplete} from './inspection-state-autocomplete';
export {QuoteStatusAutocomplete} from './quote-status-autocomplete';
export {ProductsAutocompleteMultiple} from './products-autocomplete-multiple';
export {ShipmentStatusAutocomplete} from './shipment-status-autocomplete';
export {FyiAutocomplete} from './fyi-autocomplete';
export {CancelledPartReplaceTypeAutocomplete} from './cancelled-part-replace-type-autocomplete';
export {NewPartTypeAutocomplete} from './new-part-type-autocomplete';
export {OemsAutocomplete} from './oems-autocomplete';
export {ProductSubcategoryAutocomplete} from './product-subcategory-autocomplete';
export {ProductOriginAutocomplete} from './product-origin-autocomplete';
export {TaskNameAutocomplete} from './task-name-autocomplete';
export * from './ecn-part-numbers-autocomplete-multiple';
export * from './priority-autocomplete';
export * from './ecn-part-type';
export * from './users-autocompletes';
export * from './ecn-numbers-autocomplete';
export * from './ecn-status-autocomplete';


export const AccountAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetAccountOptionsQuery();
    return (
        <Autocomplete
            options={data?.accounts.items as Omit<AccountOptionFragment, 'location'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Account`}
            {...props}
        />
    );
};

export const MissingProductsAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetMissingProductsQuery();
    return (
        <AutocompleteMultiple
            options={data?.missingProducts.items || []}
            loading={loading}
            valueKey='externalId'
            labelKey='name'
            label={t`Missing Products`}
            {...props}
        />
    );
};


type AccountPemAutocompleteProps = AutocompletePublicProps & {oemOrganizationName: string;};
export const AccountPemAutocomplete: FC<React.PropsWithChildren<AccountPemAutocompleteProps>> = ({oemOrganizationName, ...props}) => {
    const [field] = useField<string>(oemOrganizationName);
    const {data, loading} = useGetAccountOptionsQuery({
        skip: !field.value,
        variables: {options: {owner: [field.value]}, filterByUser: false},
    });

    return (
        <Autocomplete
            options={data?.accounts.items as Omit<AccountOptionFragment, 'location'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Account`}
            disabled={!field.value}
            {...props}
        />
    );
};

export const AccountPemAutocompleteWithActions: FC<React.PropsWithChildren<AccountPemAutocompleteProps>> = ({gridProps, ...props}) => {
    const {open, toggle} = useToggle();
    const {open: openDialog, toggle: toggleDialog} = useToggle();
    const [field] = useField<string>(props.name);
    const [oemOrganizationField] = useField<string>(props.oemOrganizationName);
    const {open: edit, setOpen: setEdit, setClose: setNew} = useToggle(false);

    return <>
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <AccountPemAutocomplete {...props} gridProps={{xs: 11}} />
            <Grid item={true} xs={1}>
                <Dropdown
                    open={open}
                    onClose={toggle}
                    options={[
                        {label: <AddIcon color='primary' />, onClick: () => {setNew(); toggleDialog();}},
                        {label: <EditIcon color='primary' />, onClick: () => {setEdit(); toggleDialog();}, disabled: !field.value},
                    ]}
                >
                    <IconButton style={{padding: 0}} onClick={toggle} size="large"><MoreVert /></IconButton>
                </Dropdown>
            </Grid>
        </Grid>
        <AccountDialogForm
            open={openDialog}
            toggle={toggleDialog}
            accountId={field.value}
            edit={edit}
            accountFieldName={props.name}
            initialValues={{owner: oemOrganizationField.value}}
        />
    </>;
};

export const AccountAutocompleteWithCreate: FC<React.PropsWithChildren<AutocompletePublicProps>> = ({gridProps, ...props}) => {
    const {open, toggle} = useToggle();
    const {open: openDialog, toggle: toggleDialog} = useToggle();
    const [field] = useField<string>(props.name);
    const [action, setAction] = useState<'add' | 'edit'>('add');

    return <>
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <AccountAutocomplete gridProps={{xs: 11}} {...props} />
            <Grid item={true} xs={1}>
                <Dropdown
                    open={open}
                    onClose={toggle}
                    options={[
                        {label: <AddIcon color='primary' />, onClick: () => {setAction('add'); toggleDialog();}},
                        {label: <EditIcon color='primary' />, onClick: () => {setAction('edit'); toggleDialog();}, disabled: !field.value},
                    ]}
                >
                    <IconButton style={{padding: 0}} onClick={toggle} size="large"><MoreVert /></IconButton>
                </Dropdown>
            </Grid>
        </Grid>
        <AccountDialogForm
            open={openDialog}
            toggle={toggleDialog}
            accountId={field.value}
            edit={action === 'edit'}
            accountFieldName={props.name}
        />
    </>;
};

export const AccountRegionAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetAccountRegionsQuery();
    return (
        <Autocomplete
            options={data?.accountRegions || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Region`}
            {...props}
        />
    );
};

export const useRacksAutocompleteOptions = () => {
    return [{label: t`Simple Rack`, value: 1}, {label: t`Double Rack`, value: 2}];
};

export const RacksAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const options = useRacksAutocompleteOptions();
    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='label'
            label={t`Racks`}
            disableClearable
            {...props}
        />
    );
};

export const QuantityLeftAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const options = [{label: t`pending shipment`, value: 'true'}, {label: t`without pending shipment`, value: 'false'}];
    return (
        <Autocomplete
            options={options || []}
            valueKey='value'
            labelKey='label'
            {...props}
        />
    );
};

type ContactAutocompleteWithAccountFilterProps = AutocompletePublicProps & {
    initialAccount?: string;
};

export const ContactAutocompleteWithAccountFilter: FC<React.PropsWithChildren<ContactAutocompleteWithAccountFilterProps>> = ({initialAccount, ...props}) => {
    const [field, _meta, helpers] = useField<string>(props.name);
    const [selectedAccount, setSelectedAccount] = useState<string>(initialAccount || '');
    const [getContactOptions, {data, loading}] = useGetContactOptionsLazyQuery();
    const [getContactAccount, {data: initialData}] = useGetContactAccountLazyQuery();

    const loadOptions = (accountId: string) => {
        // We need an additional variable to check if the loaded options should be displayed or not
        // because we don't have a way to clear the previously loaded options (or do we?).
        setSelectedAccount(accountId);
        if (accountId) getContactOptions({variables: {options: {account: [accountId]}}});
    };

    // Load contacts for initial account selection.
    useEffect(() => {
        if (initialAccount) getContactOptions({variables: {options: {account: [initialAccount]}}});
    }, []);

    // Load parent account for initial value (if any).
    useEffect(() => {
        if (field.value) getContactAccount({variables: {id: field.value}});
    }, []);

    // Load contact options for the parent account of the initial value.
    // This will only happen if initialData changes because of the previous useEffect.
    useEffect(() => {
        if (initialData) loadOptions(initialData.contact.account.id);
    }, [initialData]);

    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={4}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{accountId: initialData?.contact.account.id || selectedAccount}}
                    onSubmit={({accountId}, actions) => {
                        if (accountId) {
                            loadOptions(accountId);
                            helpers.setValue('');
                            actions.setSubmitting(false);
                        }
                    }}>
                    <AccountAutocomplete name='accountId' submitOnSelect={true} />
                </Formik>
                <Autocomplete
                    options={selectedAccount && data?.contacts.items || []}
                    loading={loading}
                    valueKey='id'
                    labelKey='displayName'
                    label={t`Contact`}
                    {...props}
                />
            </Grid>
        </Grid>
    );
};

export const ContactTypeAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetContactTypesQuery();
    return (
        <Autocomplete
            options={data?.contactTypes || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Contact type`}
            {...props}
        />
    );
};

export const OpportunityChannelAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetOpportunityChannelsQuery();
    return (
        <Autocomplete
            options={data?.opportunityChannels || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Channel`}
            {...props}
        />
    );
};

export const OpportunityReasonAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetOpportunityReasonsQuery();
    return (
        <Autocomplete
            options={data?.opportunityReasons || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Reason`}
            {...props}
        />
    );
};

export const OpportunitySituationAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetOpportunitySituationsQuery();
    return (
        <Autocomplete
            options={data?.opportunitySituations || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Situation State`}
            {...props}
        />
    );
};

export const BillingTypeAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {user} = useUser();

    const [_field, _meta, helpers] = useField<string>('account');
    const {setFieldValue} = useFormikContext();

    const {data} = useGetAccountOptionsQuery();

    return (
        <Grid item={true} {...props.gridProps}>
            <Field name={props.name}>
                {({field: billingField}: FieldProps) => (
                    <TextField
                        fullWidth={true}
                        variant='outlined'
                        select={true}
                        label={t`Billing type`}
                        {...billingField}
                        onChange={(event: any) => {
                            setFieldValue(props.name, event.target.value);
                            if (event.target.value === QuotesQuoteBillingTypeChoices.Indirect) {
                                helpers.setValue(
                                    data?.accounts.items.find(account => account.externalId === user?.organization?.externalId)?.id || ''
                                );
                            }
                        }}
                    >
                        <MenuItem value={QuotesQuoteBillingTypeChoices.Direct}><Trans>Direct - To Producer</Trans></MenuItem>
                        <MenuItem value={QuotesQuoteBillingTypeChoices.Indirect}><Trans>Indirect - Own purchase</Trans></MenuItem>
                    </TextField>
                )}
            </Field>
        </Grid>
    );
};

type ProductCategoryAutocompleteProps = AutocompletePublicProps & {
    productsLoading?: boolean;
    seederId?: string;
};

export const ProductCategoryAutocomplete: FC<React.PropsWithChildren<ProductCategoryAutocompleteProps>> = (props) => {
    const {data, loading, refetch} = useGetProductCategoriesQuery();

    useEffect(() => {
        if (props.seederId) refetch({seederId: props.seederId});
    }, [props.seederId]);

    return (
        <Autocomplete
            options={!props.productsLoading && data?.productCategories || []}
            loading={loading || props.productsLoading}
            valueKey='id'
            labelKey='name'
            label={t`Category`}
            {...props}
        />
    );
};

export const ProductAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetProductOptionsQuery();
    return (
        <Autocomplete
            options={data?.products.map(({id, name}) => ({id, name})) || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Product`}
            {...props}
        />
    );
};

type ProductAutocompleteWithCategoryFilterProps = AutocompletePublicProps & {
    products?: ProductFragment[];
    organizationId?: string;
    seederId?: string;
};

export const ProductAutocompleteWithCategoryFilter: FC<React.PropsWithChildren<ProductAutocompleteWithCategoryFilterProps>> = ({gridProps, disabled, seederId, ...rest}) => {
    const {data: {products = []} = {}, loading} = useGetProductOptionsQuery({
        variables: {organizationId: rest.organizationId ? rest.organizationId : undefined},
        fetchPolicy: 'cache-and-network',
    });
    const [field] = useField<string>(rest.name);
    const [category, setCategory] = useState('');

    const filteredProducts = useMemo(() => {
        return category && products?.filter(product => product.subcategory.category.id === category) || products;
    }, [category, products]);

    return (
        <Grid item={true} {...gridProps}>
            <Grid container={true} spacing={4}>
                <Formik
                    initialValues={{
                        category: filteredProducts?.find(product => product.id === field.value)?.subcategory.category.id || category,
                    }}
                    onSubmit={({category}, actions) => {
                        actions.setSubmitting(true);
                        if (category) setCategory(category);
                        else setCategory('');
                        actions.setSubmitting(false);
                    }}>
                    <ProductCategoryAutocomplete
                        name='category'
                        seederId={seederId}
                        productsLoading={loading}
                        submitOnSelect={true}
                        disabled={disabled}
                    />
                </Formik>
                <Autocomplete
                    options={filteredProducts?.map(product => ({id: product.id, displayName: product.displayName})) || []}
                    loading={loading}
                    valueKey='id'
                    labelKey='displayName'
                    label={t`Product`}
                    disabled={disabled}
                    {...rest}
                />
            </Grid>
        </Grid>
    );
};

type OemProductAutocompleteWithCategoryFilterProps = AutocompletePublicProps & {
    oemProducts?: GetOemProductsQuery['oemProducts'];
    organizationId?: string;
};

export const OemProductAutocompleteWithCategoryFilter: FC<React.PropsWithChildren<OemProductAutocompleteWithCategoryFilterProps>> = ({gridProps, oemProducts, disabled, loading, ...rest}) => {
    const [field] = useField<string>(rest.name);
    const [category, setCategory] = useState('');

    const filteredProducts = useMemo(() => {
        return category && oemProducts?.filter(product => product.product.subcategory.category.id === category) || oemProducts;
    }, [category, oemProducts]);

    return (
        <Grid item={true} {...gridProps}>
            <Grid container={true} spacing={4}>
                <Formik
                    initialValues={{
                        category: filteredProducts?.find(oemProduct => oemProduct.id === field.value)?.product.subcategory.category.id || category,
                    }}
                    onSubmit={({category}, actions) => {
                        actions.setSubmitting(true);
                        if (category) setCategory(category);
                        else setCategory('');
                        actions.setSubmitting(false);
                    }}>
                    <ProductCategoryAutocomplete
                        name='category'
                        productsLoading={loading}
                        submitOnSelect={true}
                        disabled={disabled}
                    />
                </Formik>
                <Autocomplete
                    options={filteredProducts?.map(product => ({id: product.id, displayName: product.displayName})) || []}
                    loading={loading}
                    valueKey='id'
                    labelKey='displayName'
                    label={t`Product`}
                    disabled={disabled}
                    {...rest}
                />
            </Grid>
        </Grid>
    );
};

export const OrganizationAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetOrganizationsQuery({variables: {options: {groupIsOemBool: ['false']}}});
    return (
        <Autocomplete
            options={data?.organizations.items as Pick<OrganizationType, 'id' | 'name'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Organization`}
            {...props}
        />
    );
};

export const OrganizationOemAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetOrganizationsQuery({variables: {options: {groupIsOemBool: ['true']}}});
    const [field, _meta, helpers] = useField<string>(props.name);
    const {resetForm} = useFormikContext();

    const previousOrganization = usePrevious(field.value);

    useEffect(() => {
        if (previousOrganization && field.value && previousOrganization !== field.value) {
            resetForm();
            helpers.setValue(field.value);
        }
    }, [field.value]);

    return (
        <Autocomplete
            options={data?.organizations.items as Pick<OrganizationType, 'id' | 'name'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`OEM organization`}
            {...props}
        />
    );
};

type FactoryControlOrganizationAutocompleteProps = AutocompletePublicProps & {
    organizartionGroupId?: string;
};

export const FactoryControlOrganizationAutocomplete: FC<React.PropsWithChildren<FactoryControlOrganizationAutocompleteProps>> = (props) => {
    const {data, loading} = useGetFactoryControlOrganizationsQuery({variables: {organizationGroupId: props.organizartionGroupId}});
    return (
        <Autocomplete
            options={data?.factoryControlOrganizations as Pick<OrganizationType, 'id' | 'name'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Organization`}
            {...props}
        />
    );
};

export const ChildOrganizationAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetChildOrganizationsQuery();
    return (
        <Autocomplete
            options={data?.childOrganizations as Pick<OrganizationType, 'id' | 'name'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Assigned organization`}
            {...props}
        />
    );
};

export const ChildOrganizationAutocompleteMultiple: FC<React.PropsWithChildren<{
    filter?: (option: GetChildOrganizationsQuery['childOrganizations'][number]) => boolean;
} & AutocompleteMultiplePublicProps>> = ({filter, ...props}) => {
    const {data, loading} = useGetChildOrganizationsQuery();
    const options = useMemo(() => filter
        ? data?.childOrganizations.filter(filter)
        : data?.childOrganizations,
        [data, filter],
    );
    return (
        <AutocompleteMultiple
            options={options as Pick<OrganizationType, 'id' | 'name'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Assigned organization`}
            {...props}
        />
    );
};

export const SeederBrandAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetSeederBrandsQuery();
    return (
        <Autocomplete
            options={data?.seederBrands || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Brand`}
            {...props}
        />
    );
};

type SeederBrandForOrganizationAutocompleteProps = AutocompletePublicProps & {oemOrganizationName: string;};
export const SeederBrandForOrganizationAutocomplete: FC<React.PropsWithChildren<SeederBrandForOrganizationAutocompleteProps>> = ({oemOrganizationName, ...props}) => {
    const [field] = useField<string>(oemOrganizationName);
    const [getSeederBrands, {data, loading}] = useGetSeederBrandsLazyQuery();

    useEffect(() => {
        if (field.value) getSeederBrands({variables: {organizationId: field.value}});
    }, [field.value]);

    return (
        <Autocomplete
            options={data?.seederBrands || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Brand`}
            disabled={!field.value}
            {...props}
        />
    );
};

type LocationAutocompleteProps = AutocompletePublicProps & {
    accountFieldName: string;
};

export const PemLocationAutocomplete: FC<React.PropsWithChildren<LocationAutocompleteProps>> = (props) => {
    const [getLocation, {data, loading}] = useGetLocationLazyQuery();
    const [getAccount] = useGetEditAccountLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: data => {
            helpers.setValue(data?.account.location?.id);
            data?.account.location?.id && getLocation({variables: {id: data.account.location.id}});
        },
    });
    const [accountField] = useField<string>(props.accountFieldName);
    const [_field, _meta, helpers] = useField<string | undefined>(props.name);

    useEffect(() => {
        accountField.value && getAccount({variables: {id: accountField.value}});
        !accountField.value && helpers.setValue('');
    }, [accountField.value]);

    return (
        <Autocomplete
            options={data?.location && [{id: data.location.id, path: data.location.path}] || []}
            loading={loading}
            valueKey='id'
            labelKey='path'
            label={t`Location`}
            {...props}
        />
    );
};

export const UserAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetUsersQuery();
    return (
        <Autocomplete
            options={data?.users as Pick<UserType, 'id' | 'displayName'>[] || []}
            loading={loading}
            valueKey='id'
            labelKey='displayName'
            label={t`User`}
            {...props}
        />
    );
};

type SeederAutocompleteProps = AutocompletePublicProps & {
    accountFieldName: string;
    rowCountFieldName?: string;
};

export const SeederAutocomplete: FC<React.PropsWithChildren<SeederAutocompleteProps>> = ({accountFieldName, rowCountFieldName, ...props}) => {
    const {user} = useUser();
    const [field, , helpers] = useField<string>(props.name);
    const [accountField] = useField<string>(accountFieldName);
    const {setFieldValue, initialValues = {} as any} = useFormikContext();

    const {data: dataAccount, loading: loadingAccount} = useGetAccountOptionQuery({
        variables: {id: accountField.value},
        skip: !accountField.value,
    });
    const {data, loading} = useGetSeederOptionsQuery({
        skip: !accountField.value,
        variables: dataAccount?.account.externalId && user?.organization?.externalId && dataAccount.account.externalId === user.organization.externalId
            ? undefined
            : {accountId: accountField.value}
    });

    useEffect(() => {
        if (!accountField.value) helpers.setValue('');
    }, [accountField.value]);


    useEffect(() => {
        if (!rowCountFieldName) return;
        const seeder = field.value && data?.seeders.find(({id}) => id === field.value);
        setFieldValue(rowCountFieldName, seeder ? seeder.rowCount : initialValues[rowCountFieldName], false);
    }, [data, field.value]);

    return (
        <Autocomplete
            options={data?.seeders.map(({rowCount, ...rest}) => ({...rest})) || []}
            disabled={!accountField.value}
            loading={loading || loadingAccount}
            valueKey='id'
            labelKey='displayName'
            label={t`Seeder`}
            {...props}
        />
    );
};

export const SeederAutocompleteWithCreate: FC<React.PropsWithChildren<SeederAutocompleteProps>> = ({gridProps, accountFieldName, ...props}) => {
    const [accountField] = useField<string>(accountFieldName);
    const [field] = useField<string>(props.name);
    const {open, toggle} = useToggle();
    const {open: openDialog, toggle: toggleDialog} = useToggle();
    const [action, setAction] = useState<'add' | 'edit'>('add');

    return (
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <SeederAutocomplete accountFieldName={accountFieldName} gridProps={{xs: 11}} {...props} />
            <Grid item={true} xs={1}>
                <Dropdown
                    open={open}
                    onClose={toggle}
                    options={[
                        {label: <AddIcon color='primary' />, onClick: () => {setAction('add'); toggleDialog();}},
                        {label: <EditIcon color='primary' />, onClick: () => {setAction('edit'); toggleDialog();}, disabled: !field.value},
                    ]}
                >
                    <IconButton
                        disabled={!accountField.value}
                        style={{padding: 0}}
                        onClick={toggle}
                        size="large"><MoreVert /></IconButton>
                </Dropdown>
            </Grid>
            <SeederDialogForm
                open={openDialog}
                seederFieldName={props.name}
                accountId={accountField.value}
                edit={action === 'edit'}
                toggle={toggleDialog}
                initialValues={{id: field.value}}
            />
        </Grid>
    );
};

type SeederModelAutocompleteProps = AutocompletePublicProps & {
    seederBrandFieldName: string;
};

export const SeederModelAutocomplete: FC<React.PropsWithChildren<SeederModelAutocompleteProps>> = ({seederBrandFieldName, ...props}) => {
    const [getSeederModels, {data, loading}] = useGetSeederModelsLazyQuery();
    const [seederBrandField] = useField<string>(seederBrandFieldName);

    useEffect(() => {
        if (seederBrandField.value) getSeederModels({variables: {seederBrandId: seederBrandField.value}});
    }, [seederBrandField.value]);

    return (
        <Autocomplete
            options={seederBrandField.value && data?.seederModels || []}
            loading={loading}
            disabled={!seederBrandField.value}
            valueKey='id'
            labelKey='name'
            label={t`Model`}
            {...props}
        />
    );
};

type ContactAutocompleteProps = AutocompletePublicProps & {
    accountFieldName: string;
    queryVariables?: GetContactOptionsQueryVariables;
};

export const ContactAutocomplete: FC<React.PropsWithChildren<ContactAutocompleteProps>> = ({accountFieldName, queryVariables, ...props}) => {
    const [accountField] = useField<string>(accountFieldName);
    const {data, loading} = useGetContactOptionsQuery({
        variables: {
            ...queryVariables,
            options: {
                ...(queryVariables?.options || {}),
                account: [accountField.value],
            },
        },
        skip: !accountField.value,
    });
    const [, , helpers] = useField<string>(props.name);

    useEffect(() => {
        if (!accountField.value) helpers.setValue('');
    }, [accountField.value]);

    return (
        <Autocomplete
            options={accountField.value && data?.contacts.items || []}
            loading={loading}
            disabled={!accountField.value}
            valueKey='id'
            labelKey='displayName'
            label={t`Contact`}
            {...props}
        />
    );
};

export const ContactAutocompleteWithCreate: FC<React.PropsWithChildren<ContactAutocompleteProps>> = ({gridProps, accountFieldName, ...props}) => {
    const [accountField] = useField<string>(accountFieldName);
    const {open, toggle} = useToggle();
    const {open: openDialog, toggle: toggleDialog} = useToggle();
    const [field] = useField<string>(props.name);
    const [action, setAction] = useState<'add' | 'edit'>('add');
    props.queryVariables;

    return <>
        <Grid item={true} {...gridProps} container={true} alignItems='center' justifyContent='center'>
            <ContactAutocomplete accountFieldName={accountFieldName} gridProps={{xs: 11}} {...props} />
            <Grid item={true} xs={1}>
                <Dropdown
                    open={open}
                    onClose={toggle}
                    options={[
                        {label: <AddIcon color='primary' />, onClick: () => {setAction('add'); toggleDialog();}},
                        {label: <EditIcon color='primary' />, onClick: () => {setAction('edit'); toggleDialog();}, disabled: !field.value},
                    ]}
                >
                    <IconButton
                        disabled={!accountField.value}
                        style={{padding: 0}}
                        onClick={toggle}
                        size="large"><MoreVert /></IconButton>
                </Dropdown>
            </Grid>
        </Grid>
        <ContactDialogForm
            open={openDialog}
            toggle={toggleDialog}
            accountId={accountField.value}
            edit={action === 'edit'}
            initialValues={{id: field.value}}
            contactFieldName={props.name}
            refetchQueries={props.queryVariables && [
                {query: GetContactOptionsDocument, variables: props.queryVariables}
            ]}
        />
    </>;
};

export const ChildUserAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = ({gridProps, ...props}) => {
    const {data, loading} = useGetChildUsersQuery();
    const {setFieldValue} = useFormikContext();
    const [field] = useField<string>(props.name);
    return (
        <Grid item={true} {...gridProps}>
            <MUIAutocomplete<UserType, false, typeof props['disableClearable'], false>
                options={[...(data?.childUsers || [])].sort((a, b) => (a.role?.name || '').localeCompare(b.role?.name || '')) as any}
                groupBy={(option) => option.role?.name || ''}
                getOptionLabel={(option) => option.displayName || ''}
                filterOptions={createFilterOptions({stringify: option => option.displayName})}
                onChange={(event: ChangeEvent<{}>, value: UserType | null) => {
                    setFieldValue(props.name, value ? value['id'] : '', true);
                }}
                isOptionEqualToValue={(option, selected) => option['id'] === selected['id']}
                loading={loading}
                value={(data?.childUsers.find(option => option['id'] === field.value) || null) as any}
                renderInput={(params) => <TextField {...params} label={t`Account assigned to`} variant='outlined' />}
                {...props}
            />
        </Grid>
    );
};

export const WarrantyClaimStatusAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => (
    <Autocomplete
        options={[
            {value: ProductsProductWarrantyClaimReviewStatusChoices.Approved, label: t`Approved`},
            {value: ProductsProductWarrantyClaimReviewStatusChoices.Cancelled, label: t`Cancelled`},
            {value: ProductsProductWarrantyClaimReviewStatusChoices.Pending, label: t`Pending`},
            {value: ProductsProductWarrantyClaimReviewStatusChoices.Rejected, label: t`Rejected`},
            {value: ProductsProductWarrantyClaimReviewStatusChoices.Sent, label: t`Order generated`},
        ]}
        label={t`Status`}
        valueKey='value'
        labelKey='label'
        {...props} />
);

export const MachineStartUpStatusAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => {
    return (
        <Autocomplete
            {...props}
            options={[
                {value: MachineStartUpsMachineStartUpStepChoices.Control, label: t`Administrative control`},
                {value: MachineStartUpsMachineStartUpStepChoices.Reviews, label: t`Reviews`},
                {value: MachineStartUpsMachineStartUpStepChoices.Completed, label: t`Completed`},
            ]}
            labelKey='label'
            valueKey='value'
            label={t`Status`}
        />
    );
};
export const MachineStartUpFactoryFieldChoicesAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => {
    return (
        <Autocomplete
            {...props}
            options={[
                {value: MachineStartUpsMachineStartUpFactoryStepChoices.Review, label: t`Review`},
                {value: MachineStartUpsMachineStartUpFactoryStepChoices.AdministrativeRevision, label: t`Administrative revision`},
                {value: MachineStartUpsMachineStartUpFactoryStepChoices.Billing, label: t`Billing`},
                {value: MachineStartUpsMachineStartUpFactoryStepChoices.Invoice, label: t`Invoice`},
                {value: MachineStartUpsMachineStartUpFactoryStepChoices.Workflow, label: 'Workflow'},
                {value: MachineStartUpsMachineStartUpFactoryStepChoices.Completed, label: t`Completed`},
            ]}
            labelKey='label'
            valueKey='value'
        />
    );
};

export const PaymentMethodCategoryAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetPaymentMethodCategoriesQuery();
    return (
        <Autocomplete
            options={data?.paymentMethodCategories || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            label={t`Payment method category`}
            gridProps={{xs: 12}}
            {...props}
        />
    );
};

type PaymentMethodAutocompleteWithCategoryFilterProps = AutocompletePublicProps & {
    initialCategory?: string;
    customPaymentMethodOption?: {id: string; name: string;}[];
    categoryName: string;
};

export const PaymentMethodAutocompleteWithCategoryFilter: FC<React.PropsWithChildren<PaymentMethodAutocompleteWithCategoryFilterProps>> = ({
    initialCategory, customPaymentMethodOption, categoryName, ...props
}) => {
    const [field, _meta, helpers] = useField<string>(props.name);
    const [category] = useField<string>(categoryName);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [getPaymentMethodOptions, {data, loading}] = useGetPaymentMethodOptionsLazyQuery();

    const loadOptions = (categoryId: string) => {
        setSelectedCategory(categoryId);
        if (categoryId) {
            getPaymentMethodOptions({variables: {categoryId: categoryId}});
        };
    };

    useEffect(() => {
        category.value && loadOptions(category.value);
    }, [category.value]);

    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={4}>
                <PaymentMethodCategoryAutocomplete name={categoryName} disableClearable gridProps={{xs: 4}} />
                <Grid item={true} xs={8}>
                    <Autocomplete
                        options={selectedCategory && customPaymentMethodOption && data?.paymentMethods.concat(customPaymentMethodOption) || []}
                        loading={loading}
                        valueKey='id'
                        labelKey='name'
                        label={t`Payment method`}
                        gridProps={{xs: 8}}
                        {...props}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

type PaymentMethodAutocompleteProps = AutocompletePublicProps & {
    initialCategory?: string;
    customPaymentMethodOption?: {id: string; name: string;}[];
    organizationId?: string;
};

export const PaymentMethodAutocomplete: FC<React.PropsWithChildren<PaymentMethodAutocompleteProps>> = ({
    initialCategory, customPaymentMethodOption, organizationId, ...props
}) => {
    const {data, loading} = useGetPaymentMethodOptionsQuery({variables: {organizationId: organizationId || undefined}});

    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                    <Autocomplete
                        options={data?.paymentMethods || []}
                        loading={loading}
                        valueKey='id'
                        labelKey='name'
                        label={t`Payment method`}
                        gridProps={{xs: 8}}
                        {...props}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export const OemShipmentStatusAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => {
    return (
        <Autocomplete
            options={[
                {value: OemsShipmentOemStatusChoices.Confirmed, label: 'Confirmado'},
                {value: OemsShipmentOemStatusChoices.Cancelled, label: 'Cancelado'},
            ]}
            labelKey='label'
            valueKey='value'
            {...props}
        />
    );
};


export const WarrantyClaimIdsAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => {
    const {data, loading} = useGetWarrantyClaimsIdsQuery({fetchPolicy: 'cache-and-network'});
    return (
        <Autocomplete
            options={data?.productWarrantyClaims.items || []}
            labelKey='id'
            valueKey='id'
            loading={loading}
            label='ID'
            {...props}
        />
    );
};

export const WarrantyClaimIdsAutocompleteMultiple: FC<React.PropsWithChildren<AutocompleteMultiplePublicProps>> = props => {
    const {data, loading} = useGetWarrantyClaimsIdsQuery({fetchPolicy: 'cache-and-network'});
    return (
        <AutocompleteMultiple
            options={data?.productWarrantyClaims.items || []}
            labelKey='displayId'
            valueKey='id'
            loading={loading}
            label='ID'
            {...props}
        />
    );
};
