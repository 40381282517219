import {t, Trans} from '@lingui/macro'
import {Button, Grid, Typography} from '@mui/material'
import {useGetRuntimeSettingsQuery} from 'generated/graphql'
import React, {FC} from 'react'

import {FileInput} from '../file-input'

interface AccountDocumentationFilesInputProps {
    name: string;
}

export const AccountDocumentationFilesInput: FC<React.PropsWithChildren<AccountDocumentationFilesInputProps>> = ({name}) => {
    const {data: documentsData} = useGetRuntimeSettingsQuery();

    return (
        <>
            <FileInput
                enableDragAndDrop={true}
                name={name}
                title={t`Attach the necessary documentation to register the account`}
                accept={{'image/*': [], 'application/pdf': []}}
            />
            <Grid container={true} item={true}>
                <Button variant='contained' color='primary' onClick={() => window.open(documentsData?.runtimeSettings?.templateOfAffidavit)}>
                    <Trans>Download DDJJ</Trans>
                </Button>
            </Grid>
            <Grid item={true} xs={6}>
                <Typography variant='h6'>{t`Legal persons:`}</Typography>
                {documentsData?.runtimeSettings?.legalPersonsDocumentation?.split(',').map((item, index) => (
                    <Typography variant='subtitle2' key={index}>- {item}</Typography>
                ))}
            </Grid>
            <Grid item={true} xs={6}>
                <Typography variant='h6'>{t`Physical persons:`}</Typography>
                {documentsData?.runtimeSettings?.physicalPersonsDocumentation?.split(',').map((item, index) => (
                    <Typography variant='subtitle2' key={index}>- {item}</Typography>
                ))}
            </Grid>
        </>
    );
};
