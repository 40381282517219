import * as Apollo from '@apollo/client';
import {t} from '@lingui/macro';
import {Button, CircularProgress, Paper, Stack, Typography} from '@mui/material';
import {useToggle} from 'core/hooks/use-toggle';
import {JobStatus, useGetExportFileJobQuery} from 'generated/graphql';
import {SnackbarKey, useSnackbar} from 'notistack';
import {useCallback, useEffect, useRef, useState} from 'react';

type Mutation<TData = any, TVariables = Apollo.OperationVariables> = (
    baseOptions?: Apollo.MutationHookOptions<TData, TVariables>
) => Apollo.MutationTuple<TData, TVariables>;

export type UseAsyncDownloadFileProps<TData = any, TVariables = Apollo.OperationVariables> = {
    hideSnackbars?: boolean;
    useHookMutation: Mutation<TData, TVariables>;
    jobIdGetter: (data: TData) => string | undefined;
    sucessMessage?: string;
    failMessage?: string;
    onCompleted?: () => void;
    onError?: () => void;
};

export function useAsyncDownloadFile<TData, TVariables = Apollo.OperationVariables>({
    useHookMutation,
    jobIdGetter,
    onError,
    onCompleted,
    failMessage = 'Algo salió mal, intenta más tarde',
    sucessMessage = t`PDF generated!`,
    hideSnackbars,
}: UseAsyncDownloadFileProps<TData, TVariables>) {
    const [job, setJob] = useState<string>();
    const {open: loading, toggle: toggleLoading, setClose: setLoadingFalse} = useToggle();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const downloadingSnackbarRef = useRef<SnackbarKey>();

    const closeDownloadingSnackbar = () =>
        downloadingSnackbarRef.current && closeSnackbar(downloadingSnackbarRef.current);

    const [_mutate] = useHookMutation({
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (jobIdGetter(data)) {
                setJob(jobIdGetter(data));
                if (!hideSnackbars) {
                    downloadingSnackbarRef.current = enqueueSnackbar({
                        variant: 'default',
                        message: 'Descargando...',
                        content: (key, message) => {
                            return (
                                <Paper sx={{p: t => t.spacing(1)}}>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        spacing={2}>
                                        <CircularProgress size={24} />
                                        <Typography>{message}</Typography>
                                        <Button
                                            onClick={() => {
                                                setJob(undefined);
                                                toggleLoading(false);
                                                closeSnackbar(key);
                                            }}>
                                            Cancelar
                                        </Button>
                                    </Stack>
                                </Paper>
                            );
                        },
                        autoHideDuration: null,
                    });
                }
            }
        },
        onError: () => {
            enqueueSnackbar('Algo salió mal, intenta más tarde', {variant: 'error'});
            toggleLoading();
            closeDownloadingSnackbar();
            onError?.();
        }
    });
    const mutate = useCallback(async (...args: Parameters<typeof _mutate>) => {
        toggleLoading();
        return _mutate(...args);
    }, [_mutate]);

    const {data} = useGetExportFileJobQuery({
        fetchPolicy: 'no-cache',
        skip: !Boolean(job),
        variables: {id: job!},
        pollInterval: 1000 * 5,
        onError: () => {
            setJob(undefined);
            setLoadingFalse();
        },
    });

    useEffect(() => {
        if (!data) return;
        if (data.exportFileJob?.status === JobStatus.Failed) {
            enqueueSnackbar(failMessage, {variant: 'error'});
            setLoadingFalse();
            closeDownloadingSnackbar();
            return setJob(undefined);
        }
        if (data.exportFileJob?.status === JobStatus.Finished) {
            const {filename, contentType, data: base64Data} = data.exportFileJob!.result!;
            setJob(undefined);
            enqueueSnackbar(sucessMessage, {variant: 'success'});
            const link = document.createElement('a');
            link.setAttribute('download', filename || 'download.pdf');
            link.href = `data:${contentType};filename=${filename};base64,${base64Data}`;
            link.click();
            setLoadingFalse();
            closeDownloadingSnackbar();
            onCompleted?.();
        }
    }, [data]);

    const cancelFetch = useCallback(() => {
        closeSnackbar(downloadingSnackbarRef.current);
        setJob(undefined);
        toggleLoading(false);
    }, [setJob, toggleLoading, closeSnackbar]);


    return [mutate, {loading, cancelFetch}] as const;
}