import {Trans} from '@lingui/macro';
import {AccountCircle as AccountCircleIcon} from '@mui/icons-material';
import {Button, IconButton, Menu, MenuItem, Theme, useMediaQuery} from '@mui/material';
import {NotificationBell} from 'components/notifications';
import {useUser} from 'contexts/user';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {FC} from 'react';
import * as settings from 'settings';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
    }
}));

const UserMenu: FC = () => {
    const {classes} = useStyles();
    const router = useRouter();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const {user} = useUser();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = async () => {
        handleClose();
        router.push('/users/profile');
    };

    const handleLogout = async () => {
        handleClose();
        router.push(settings.LOGOUT_URL);
    };

    if (!user) return null;

    return (
        <div className={classes.root}>
            {user && user.inAppNotifications && <NotificationBell />}
            {user && (smDown
                ? <IconButton onClick={handleOpen} color='primary' size="large"><AccountCircleIcon /></IconButton>
                : <Button onClick={handleOpen} color='primary' startIcon={<AccountCircleIcon />}>{user.displayName}</Button>
            )}
            {!user && (
                <Link href={settings.LOGIN_URL} passHref={true}>
                    <Button color='primary'>
                        Login
                    </Button>
                </Link>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <li>
                    <MenuItem onClick={handleProfile} component='a'><Trans>Profile</Trans></MenuItem>
                </li>
                <MenuItem onClick={handleLogout}><Trans>Sign out</Trans></MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
