import {
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControlLabel,
    FormControlLabelProps,
    Grid,
} from '@mui/material'
import {FastField, FieldProps} from 'formik'
import React, {FC} from 'react'

import {defaultGridProps, FormChild} from './form'

type BaseCheckboxProps = {
    name: string;
};

export type CheckboxProps = FormChild
    & BaseCheckboxProps
    & Pick<FormControlLabelProps, 'label'>
    & Pick<MuiCheckboxProps, 'color' | 'disabled'> 

export const Checkbox: FC<React.PropsWithChildren<CheckboxProps>> = ({
    name,
    label,
    disabled,
    gridProps,
    color = 'primary',
}) => {

    const component = (
        <FastField name={name}>
            {({field}: FieldProps) => (
                <FormControlLabel
                    label={label}
                    disabled={disabled}
                    control={
                        <MuiCheckbox
                            color={color}
                            checked={field.value}
                            disabled={disabled}
                            {...field}
                        />
                    }
                />
            )}
        </FastField>
    );

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
};

Checkbox.defaultProps = {
    gridProps: defaultGridProps,
};
