import {Box, Typography} from '@mui/material';
import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms/autocomplete-multiple';
import {useGetRolesQuery} from 'generated/graphql';
import {FC} from 'react';

export const FyiAutocomplete: FC<AutocompleteMultiplePublicProps> = props => {
    const {data: {roles} = {}, loading} = useGetRolesQuery();

    return (
        <AutocompleteMultiple
            options={roles || []}
            valueKey='id'
            loading={loading}
            labelKey='name'
            {...props}
            renderOption={(props, option) => (
                <Box component='li' {...props}>
                    {option.name}
                    <Typography component='span' sx={{ml: 1.5}} variant='caption' noWrap={true}>
                        {option.userSet.map(({displayName}) => displayName).join(', ')}
                    </Typography>
                </Box>
            )}
        />
    );
};
