import {Grid} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            '&& > *': {
                width: '100%',
            },
            '&& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            '&& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    }
}));

export const FormActions: FC<React.PropsWithChildren> = ({children}) => {
    const {classes} = useStyles();
    return (
        <Grid item={true} xs={12} className={classes.root}>
            {children}
        </Grid>
    );
};
