import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@mui/icons-material';
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {Link} from 'components/link';
import {useUser} from 'contexts/user';
import {UserFragment} from 'generated/graphql';
import {useRouter} from 'next/router';
import React, {FC, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    selected: {
        color: theme.palette.secondary.light,
        backgroundColor: 'transparent !important',
    },

    nested: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    }
}));

interface AppMenuLinkProps {
    href: string;
    label: string;
    icon: React.ReactElement;
    children?: React.ReactNode,
    newTab?: boolean,
    hrefExternal?: boolean,
    permission?: keyof UserFragment['permissions'] | (keyof UserFragment['permissions'])[];
}

const AppMenuLink: FC<React.PropsWithChildren<AppMenuLinkProps>> = ({href, label, icon, children, newTab, hrefExternal, permission}) => {
    const {classes} = useStyles();
    const router = useRouter();
    const {user} = useUser();
    const isExternalLink = href.startsWith('https://') || href.startsWith('http://');
    const isExpandable = Boolean(children);
    const [expanded, setExpanded] = useState<boolean>(router.pathname.replace(router.basePath, '').startsWith(href));

    const linkIsActive = !isExpandable && (
        href === '/'
            ? router.pathname.replace(router.basePath, '') === href
            : router.pathname.replace(router.basePath, '').startsWith(href)
    );

    const button = (
        <ListItemButton
            selected={linkIsActive}
            classes={{selected: classes.selected}}
            onClick={() => isExpandable && setExpanded(!expanded)}>
            <ListItemIcon>
                {React.cloneElement(icon, {color: linkIsActive ? 'secondary' : 'inherit'})}
            </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{sx: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}} />
            {isExpandable && (
                expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
            )}
        </ListItemButton>
    );

    if (permission && user) {
        if (Array.isArray(permission)) {
            if (!permission.every(p => Boolean(user.permissions[p]))) return null;
        } else if (!Boolean(user.permissions[permission])) return null;
    };

    if (isExternalLink || newTab) {
        return React.cloneElement(button, {href, target: '_blank'});
    }

    return (
        <>
            {isExpandable
                ? button
                : hrefExternal
                    ? <a style={{textDecoration: 'none', color: 'unset'}} href={href}>{button}</a>
                    : <Link href={href}>{button}</Link>
            }
            {isExpandable && (
                <Collapse in={expanded}>
                    <List disablePadding={true} className={classes.nested}>
                        {children}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default AppMenuLink;
