import {t, Trans} from '@lingui/macro';
import {Block as EmptyIcon, Clear as ClearSearchIcon, Search as SearchIcon, Tune as TuneIcon} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputBase,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material/styles';
import {AsyncTablePagination} from 'components/async-table/async-table-pagination';
import {ColumnSelector} from 'components/async-table/column-selector';
import {useUserColumns} from 'components/async-table/use-user-columns';
import {useDebounce, useLocalStorage} from 'core/hooks';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {useRouter} from 'next/router';
import {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Paths} from 'types';
import {decodeFromB64, encodeToB64} from 'utils';

import {theme as defaultTheme} from './theme';



const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        overflowWrap: 'break-word',
        '&& > tr > td:first-child': {
            overflowWrap: 'normal',
        },
    },

    search: {
        alignSelf: 'center',
        display: 'flex',
        flexGrow: 1,
        '&& > * + *': {
            marginLeft: theme.spacing(2),
            flexGrow: 1,
        },
    },

    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4),
    },

    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(4),
        '&& > * + *': {
            marginTop: theme.spacing(1),
        },
    },

    clickableRow: {
        cursor: 'pointer',
    },

    expanded: {
        backgroundColor: theme.palette.grey[100],
    },

    filterActions: {
        textAlign: 'right',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    summary: {
        '&.Mui-focused': {
            backgroundColor: 'unset',
        },
    },

    expansionPanel: {
        border: 'unset',
    },

    paginationSpacer: {
        flex: 1,
    },

    extraRow: {
        padding: 0,
        backgroundColor: theme.palette.grey[100],
    }
}));

export type Column<T> = {
    label?: string;
    key?: Paths<T> | string;
    ordering?: boolean;
    orderingKey?: Paths<T> | string;
    format?(value: any): React.ReactNode;
    render?(row: T, indexRow: number, indexCol: number): React.ReactNode;
    theme?: Theme;
    tableCellProps?: TableCellProps;
};

export type TableOptions = {
    page: number;
    initialPageSize: number;
    ordering: string;
    query: string;
};

export type AsyncTableQuerystringProps = {
    page: string;
    pageSize: string;
    expanded: string;
    filters: string;
    q: string;
    order: string;
};

export type AsyncTableProps<T> = {
    data: T[];
    count: number;
    loading: boolean;
    columns: Column<T>[];
    initialPageSize: number;
    size: 'small' | 'medium';
    theme?: Theme;
    initialPage?: number;
    pageIndexBase?: 0 | 1;
    initialFilters?: T;
    initialOrdering?: string;
    initialQuery?: string;
    filterComponents?: ReactNode[];
    fetchData(options: TableOptions): any;
    getRowLink?(row: T): {url: string, as?: string;};
    disableToolbar?: boolean;
    disablePagination?: boolean;
    extraRow?: (row: T) => React.ReactNode;
    id: string;
};

export function AsyncTable<T extends object>({
    id,
    data,
    count,
    loading,
    columns,
    initialPageSize: initialPageSize,
    size,
    theme = defaultTheme,
    initialPage = 0,
    pageIndexBase = 0,
    initialFilters = {} as T,
    initialQuery = '',
    initialOrdering,
    filterComponents,
    disableToolbar,
    disablePagination,
    fetchData,
    getRowLink,
    extraRow,
}: AsyncTableProps<T>) {
    const {visibleColumns, hiddenColumns, isColumnVisible} = useUserColumns({id, columns});
    const router = useRouter();
    const {classes} = useStyles();
    const searchInputRef = useRef<HTMLInputElement>();
    const [querystring, setQuerystring] = useLocalStorage<AsyncTableQuerystringProps>(`querystring-${id}`, {} as AsyncTableQuerystringProps);
    const [expandedFilters, setExpandedFilters] = useState(querystring.expanded === 'true' || false);
    const [filtersValues, setFiltersValues] = useState<T>(() => querystring.filters ? decodeFromB64(querystring.filters) : initialFilters);
    const [pageSize, setPageSize] = useState(Number(querystring.pageSize) || initialPageSize);

    const [page, setPage] = useState(Number(querystring.page) || initialPage);
    const [ordering, setOrdering] = useState(querystring.order || initialOrdering || `+${columns[0].key}`);
    const [query, setQuery] = useState(querystring.q || initialQuery);

    const debouncedPage = useDebounce(page, 250);
    const debouncedQuery = useDebounce(query, 250);

    const handleToggleFilters = useCallback(() => {
        setQuerystring({...querystring, expanded: (!expandedFilters).toString()});
        setExpandedFilters(current => !current);
    }, [setExpandedFilters]);

    const onSubmitFilters = (values: any, helpers: FormikHelpers<T>) => {
        //Delete empty values
        setFiltersValues(Object.keys(values).reduce((acc, key) => (values[key] === '' ? acc : {...acc, [key]: values[key]}), {}) as T);
        setPage(initialPage);
        helpers.setSubmitting(false);
    };

    const handleResetFilters = (formikProps: FormikProps<T>) => {
        formikProps.resetForm();
        setFiltersValues(({} as T));
        setQuerystring({
            ...querystring,
            page: initialPage.toString(),
            filters: encodeToB64({}),
        });
    };

    useEffect(() => {
        setQuerystring({
            ...querystring,
            page: debouncedPage,
            q: debouncedQuery,
            order: ordering,
            pageSize: pageSize.toString(),
            filters: encodeToB64(filtersValues),
        });
        fetchData({
            pageSize,
            ordering,
            page: (debouncedPage + pageIndexBase),
            query: debouncedQuery,
            ...filtersValues
        } as any);
    }, [debouncedPage, ordering, debouncedQuery, filtersValues, pageSize]);

    const toolbar = useMemo(() => {
        return (
            <Formik initialValues={filtersValues} enableReinitialize={true} onSubmit={onSubmitFilters}>
                {(formikProps: FormikProps<T>) => {
                    return (
                        <Form>
                            <Accordion expanded={expandedFilters} className={classes.expansionPanel}>
                                <AccordionSummary className={classes.summary}>
                                    <Grid container={true} justifyContent='space-between' spacing={1}>
                                        <Grid item={true} className={classes.search}>
                                            <IconButton
                                                size='small'
                                                onClick={() => {
                                                    if (query) setQuery('');
                                                    else searchInputRef.current?.focus();
                                                }}>
                                                {query ? <ClearSearchIcon /> : <SearchIcon />}
                                            </IconButton>
                                            <InputBase
                                                placeholder={t`Search`}
                                                inputRef={searchInputRef}
                                                value={query}
                                                onChange={event => {
                                                    setQuery(event.target.value);
                                                    setPage(initialPage);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ColumnSelector id={id} columns={columns} />
                                        </Grid>
                                        {filterComponents && (
                                            <Grid item={true}>
                                                <Button
                                                    endIcon={<TuneIcon color='primary' />}
                                                    className={expandedFilters ? classes.expanded : ''}
                                                    onClick={handleToggleFilters}>
                                                    <Trans>Filter</Trans>
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <Grid container={true}>
                                        <Grid container={true} spacing={3}>
                                            {filterComponents?.map((component, index) => (
                                                <Grid item={true} key={index} xs={12} md={6} lg={3}>
                                                    {component}
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid item={true} xs={12} className={classes.filterActions}>
                                            <Button onClick={() => handleResetFilters(formikProps)}>
                                                <Trans>Clean</Trans>
                                            </Button>
                                            <Button color='primary' type='submit'>
                                                <Trans>Apply</Trans>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Form>
                    );
                }}
            </Formik>
        );
    }, [query, searchInputRef.current, expandedFilters]);

    const tableHead = useMemo(() => {
        if (columns.every(col => !col.label)) return <TableRow><TableCell padding='none' colSpan={columns.length} /></TableRow>;
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column, i) => {
                        if (!isColumnVisible(column.key)) return null;
                        return (
                            <TableCell key={`${column.key}.${i}`} {...column.tableCellProps}>
                                {column.ordering === true
                                    ? (
                                        <TableSortLabel
                                            active={ordering.slice(1) === (column.orderingKey || column.key)}
                                            direction={ordering[0] === '-' ? 'desc' : 'asc'}
                                            onClick={() => {
                                                const direction = ordering[0] === '+' ? '-' : '+';
                                                setOrdering(`${direction}${(column.orderingKey || column.key)}`);
                                            }}>
                                            {column.label}
                                        </TableSortLabel>
                                    )
                                    : column.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    }, [columns, ordering, visibleColumns, hiddenColumns]);

    const extraRowRef = useRef<HTMLTableRowElement>(null);

    const tableBody = (
        <TableBody className={classes.root}>
            {!loading && data.map((row, indexRow) => (
                <>
                    <TableRow
                        key={indexRow}
                        className={getRowLink ? classes.clickableRow : undefined}
                        hover={Boolean(getRowLink)}
                        onClick={() => {
                            if (getRowLink) {
                                const link = getRowLink(row);
                                router.push(link.url, link.as);
                            }
                        }}>
                        {columns.map((column, indexCol) => {
                            if (!isColumnVisible(column.key)) return null;
                            const value = column.key && getValue(row, column.key);
                            return (
                                <TableCell key={`${column.key}.${indexRow}.${indexCol}`} {...column.tableCellProps}>
                                    {column.render
                                        ? column.render(row, indexRow, indexCol)
                                        : (column.format ? column.format(value) : value)
                                    }
                                </TableCell>
                            );
                        })}
                    </TableRow>
                    {extraRow && (
                        <TableRow key={`${indexRow}.extraRow`} className={classes.extraRow} ref={extraRowRef}>
                            <TableCell padding='none' style={{maxWidth: extraRowRef.current?.scrollWidth || 0}} colSpan={columns.length} >
                                {extraRow(row)}
                            </TableCell>
                        </TableRow>
                    )}
                </>
            ))}
        </TableBody>
    );

    const pagination = useMemo(() => (
        <TablePagination
            classes={{spacer: classes.paginationSpacer}}
            component='div'
            count={count}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 30, 50]}
            labelRowsPerPage={t`Rows per page`}
            labelDisplayedRows={() => ''}
            onRowsPerPageChange={e => setPageSize(Number(e.target.value))}
            onPageChange={(_event, newPage) => setPage(newPage)}
            ActionsComponent={AsyncTablePagination}
        />
    ), [count, page, initialPageSize, pageSize]);

    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Paper elevation={0}>
                            {disableToolbar !== true && toolbar}
                            <TableContainer>
                                <Table size={size}>
                                    {tableHead}
                                    {tableBody}
                                </Table>
                            </TableContainer>
                            {loading && (
                                <div className={classes.loading}>
                                    <CircularProgress color='primary' />
                                </div>
                            )}
                            {!loading && data.length === 0 && (
                                <div className={classes.empty}>
                                    <EmptyIcon />
                                    <div><Trans>No data available</Trans></div>
                                </div>
                            )}
                            {disablePagination !== true && data.length > 0 && pagination}
                        </Paper>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Grid>
        </Grid>
    );
}

AsyncTable.defaultProps = {
    loading: false,
    initialPageSize: 30,
    size: 'medium',
} as Partial<AsyncTableProps<any>>;

export const getValue = (row: any, key: string) => {
    let value = row;
    const keyPath = key.split('.');
    while (value && keyPath.length) {
        value = value[keyPath.shift() as string];
    }
    return value;
};
