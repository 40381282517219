import {tableCellClasses} from '@mui/material';
import {alpha, createTheme} from '@mui/material/styles';
import {VictoryTheme} from 'victory';
import '@mui/material/TableCell';

const defaultTheme = createTheme();

export const theme = createTheme({
    shape: {
        borderRadius: 6,
    },
    palette: {
        primary: {
            main: '#00966C',
            light: '#00966C21',
        },
        secondary: {
            main: '#ED8B00',
        },
        error: {
            main: '#B0001F',
            light: '#FDECEA',
        },
        background: {
            default: '#EDEDED',
        },
    },
    typography: {
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 'normal',
        },
        h5: {
            color: defaultTheme.palette.grey[700],
            fontSize: '1.25rem',
        },
        h6: {
            fontSize: '1rem',
            color: defaultTheme.palette.grey[500],
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: `${defaultTheme.spacing(2)} ${defaultTheme.spacing(3)}`,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: `1px solid ${defaultTheme.palette.divider}`,
                    boxShadow: 'none',
                    '@media print': {
                        border: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '@media print': {
                        padding: 0,
                    },
                },
                content: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&.Mui-expanded': {
                        margin: 0,
                        '@media print': {
                            minHeight: 'unset',
                        },
                    },
                },
                expandIconWrapper: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    '@media print': {
                        display: 'none',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                    '@media print': {
                        padding: 0,
                    },
                },
            },
        },
        MuiAccordionActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                    justifyContent: 'flex-start',
                },
            },
        },
        MuiTable: {
            variants: [
                {
                    props: {color: 'green'},
                    style: ({theme}) => ({
                        [`& .${tableCellClasses.head}`]: {
                            backgroundColor: theme.palette.primary.light,
                        },
                    }),
                },
            ],
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child > td': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    '@media print': {
                        lineHeight: '0.9rem',
                    },
                },
                sizeSmall: {
                    '@media print': {
                        padding: `3px 10px 3px 10px`,
                    },
                },
            },
            variants: [
                {
                    props: {size: 'action'},
                    style: {
                        width: '1px',
                        whiteSpace: 'nowrap',
                        padding: 0,
                    },
                },
            ]
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: '0.875rem'
                }
            }
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
            },
            styleOverrides: {
                filledError: {
                    backgroundColor: alpha('#B0001F', 0.7),
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: defaultTheme.palette.background.paper,
                },
            },
        },
    },
});

type VictoryThemeDefinition = typeof VictoryTheme.material;
export const victoryTheme: VictoryThemeDefinition = {
    ...VictoryTheme.material,
    axis: {
        ...VictoryTheme.material.axis,
        style: {
            ...VictoryTheme.material.axis?.style,
            axis: {
                ...VictoryTheme.material.axis?.style?.axis,
                stroke: theme.palette.grey[300],
            },
            grid: {
                ...VictoryTheme.material.axis?.style?.grid,
                stroke: theme.palette.grey[300],
            },
            ticks: {
                ...VictoryTheme.material.axis?.style?.ticks,
                stroke: theme.palette.grey[300],
            },
            tickLabels: {
                ...VictoryTheme.material.axis?.style?.tickLabels,
                fontFamily: theme.typography.fontFamily,
                stroke: theme.palette.grey[500],
            },
        },
    },
    line: {
        ...VictoryTheme.material.line,
        style: {
            ...VictoryTheme.material.line?.style,
            data: {
                ...VictoryTheme.material.line?.style?.data,
                stroke: theme.palette.primary.main,
            },
        },
    },
    histogram: {
        ...VictoryTheme.material.line,
        style: {
            ...VictoryTheme.material.histogram?.style,
            data: {
                ...VictoryTheme.material.histogram?.style?.data,
                fill: theme.palette.primary.main,
            }
        }
    },
};



declare module '@mui/material/TableCell' {
    interface TableCellPropsSizeOverrides {
        action: true;
    }
}