import {CircularProgress} from '@mui/material';
import {GraphQLErrors} from 'components/graphql-errors';
import {useGetUserQuery, UserFragment} from 'generated/graphql';
import {createContext, FC, useContext} from 'react';

type UserContextType = {
    user?: UserFragment;
};

const UserContext = createContext<UserContextType>({
    user: undefined,
});

export const UserProvider: FC<React.PropsWithChildren> = ({children}) => {
    const {data, loading, error, refetch} = useGetUserQuery({fetchPolicy: 'cache-first'});

    return (
        <UserContext.Provider value={{user: data?.user}}>
            {error && <GraphQLErrors error={error} refetch={refetch} />}
            {loading ? <CircularProgress color='primary' /> : children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
