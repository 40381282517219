import {Backdrop, Box, CircularProgress, Grid, GridProps, GridSpacing, Typography} from '@mui/material'
import {useToggle} from 'core/hooks'
import {Form as FormikForm, FormikFormProps, useFormikContext} from 'formik'
import {FC, useEffect} from 'react'
import {makeStyles} from 'tss-react/mui'

type FormProps = Pick<FormikFormProps, 'autoComplete'> & {
    spacing?: GridSpacing;
    disabled?: boolean;
    withBackdrop?: boolean;
} & Pick<GridProps, 'alignItems' | 'alignContent'>;

export const Form: FC<React.PropsWithChildren<FormProps>> = ({children, autoComplete, spacing = 4, disabled, withBackdrop, ...gridProps}) => {
    const {isSubmitting} = useFormikContext();
    const { classes } = useStyles();
    const {open, setOpen, setClose} = useToggle();

    useEffect(() => isSubmitting ? setOpen() : setClose(), [isSubmitting]);

    return (
        <FormikForm autoComplete={autoComplete}>
            <Grid container={true} spacing={spacing} style={disabled ? {pointerEvents: 'none', opacity: 0.7} : undefined} {...gridProps}>
                {children}
                {withBackdrop && (
                    <Backdrop className={classes.backdrop} open={open} onClick={setClose}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            bgcolor='white'
                            height='20vh'
                            width='20vh'
                            borderRadius="10px"
                            justifyContent='space-evenly'
                        >
                            <Typography color='textPrimary'>
                                Cargando...
                            </Typography>
                            <CircularProgress color='primary' size={48} />
                        </Box>
                    </Backdrop>
                )}
            </Grid>
        </FormikForm>
    );
};

Form.defaultProps = {
    autoComplete: 'off',
};

// Form components must wrap themselves inside a grid item
// with these default props, and allow the user to override
// them with the optional gridProps prop.
// If gridProps is null then the component should not wrap
// itself with a grid item.

export const defaultGridProps: GridProps = {
    xs: 12,
    sm: 6,
};

export type FormChild = {
    gridProps?: GridProps | false;
};

const useStyles = makeStyles()(theme =>
    ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }));
