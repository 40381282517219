import {Trans} from '@lingui/macro';
import {Clear as ClearIcon} from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {FormHelperText, Grid, IconButton, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useField, useFormikContext} from 'formik';
import dynamic from 'next/dynamic';
import {flushSync} from 'react-dom';
import React, {FC, useEffect, useState} from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import {makeStyles} from 'tss-react/mui';

interface FileInputProps {
    name: string;
    accept?: Accept;
    title?: string;
    label?: string;
    uniqueFile?: boolean;
    enableDragAndDrop?: boolean;
    submitOnChange?: boolean;
    disabled?: boolean;
    component?: React.ReactNode;
    helperText?: React.ReactNode;
}

const PDFThumb = dynamic(() => import("components/file-input/pdf-thumb"), {
    ssr: false
});

export const FileInput: FC<React.PropsWithChildren<FileInputProps>> = ({name, accept, title, label, uniqueFile, enableDragAndDrop = true, submitOnChange, disabled, component, helperText}) => {
    const {classes} = useStyles();

    const {isSubmitting, submitForm} = useFormikContext();
    const [_field, _meta, helpers] = useField<File[] | File | null>(name);

    const [files, setFiles] = useState<(File & {preview: string;})[]>([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: accept,
        onDrop: acceptedFiles => {
            flushSync(() => {
                if (files.find(file => acceptedFiles.map(af => af.name).includes(file.name))) return;
                setFiles(prevFiles => prevFiles.concat(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))));
                helpers.setValue(uniqueFile ? acceptedFiles[0] : [...files.concat(acceptedFiles as any)]);
                if (!enableDragAndDrop && submitOnChange) {
                    submitForm();
                }
            });
        }
    });

    const thumbs = files.map(file => (
        <div key={file.name} className={classes.thumb}>
            <div className={classes.thumbInner}>
                {file.type.includes('video') && (
                    <video className={classes.img}>
                        <source src={file.preview} type={file.type} />
                    </video>
                )}
                {file.type.includes('image') && <img src={file.preview} className={classes.img} />}
                {file.type.includes('pdf') && <PDFThumb className={classes.img} file={file} />}
            </div>
            {!disabled && <ClearIcon fontSize='small' onClick={() => removeImage(file.name)} />}
        </div>
    ));

    const removeImage = (fileName: string) => {
        const _files = files.filter(file => file.name !== fileName);
        setFiles(_files);
        helpers.setValue(_files);
    };

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    if (!enableDragAndDrop) {
        return (
            <div {...getRootProps()}>
                <input {...getInputProps({multiple: true, disabled: isSubmitting})} disabled={disabled} />
                {component || (
                    <IconButton disabled={disabled} size="large">
                        <AttachFileIcon />
                    </IconButton>
                )}
            </div>
        );
    }

    return (
        <Grid item={true} xs={12} >
            {title && <Typography variant='body1'>{title}</Typography>}
            <FormHelperText error={_meta.touched && Boolean(_meta.error)}>
                {_meta.touched && _meta.error}
            </FormHelperText>
            {!(uniqueFile && files.length > 0)
                ? (<div {...getRootProps({className: `${classes.root} ${_meta.touched && Boolean(_meta.error) ? classes.rootError : ''}`})}>
                    <input {...getInputProps({multiple: true, disabled: isSubmitting})} disabled={disabled} />
                    {label || <Trans>Drag and drop some files here, or click to select a files</Trans>}
                </div>
                ) : <div>{label}</div>}
            <aside className={classes.thumbsContainer}>
                {thumbs}
            </aside>
            {helperText && (
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            )}
        </Grid>
    );
};

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(4),
        border: `1px ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'dashed',
        textAlign: 'center',
        justifyContent: 'center',
        outline: 'none',
        cursor: 'pointer',
        transition: `border ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },

    rootError: {
        borderColor: `${theme.palette.error.main} !important`,
    },

    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        padding: theme.spacing(1),
    },

    img: {
        display: 'block',
        width: 'auto',
        height: '100%',
    },

    thumb: {
        display: 'inline-flex',
        width: theme.spacing(20),
        height: theme.spacing(20),
        padding: theme.spacing(2),
        boxSizing: 'border-box',
    },

    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    thumbLabel: {
        maxWidth: theme.spacing(20),
    },

    clearIcon: {
        cursor: 'pointer',
    }
}));
