import {t, Trans} from '@lingui/macro';
import {Button} from '@mui/material';
import {AccountAutocomplete, AccountAutocompleteWithCreate, ContactTypeAutocomplete} from 'components/autocompletes';
import {Form, FormActions, FormErrors, SubmitButton, TextInput} from 'core/components/forms';
import {Formik, FormikErrors, FormikHelpers} from 'formik';
import {CreateContactMutationInput, UpdateContactMutationInput} from 'generated/graphql';
import React, {FC, useState} from 'react';

type Values = CreateContactMutationInput | UpdateContactMutationInput;

export interface ContactFormProps {
    initialValues?: Partial<Values>;
    onSubmit(values: Values, actions: FormikHelpers<Values>): void;
    onCancel?(): void;
    validate?: (values: Partial<Values>) => FormikErrors<Values>;
    initialStatus?: {formErrors?: string;} | false;
}

export const ContactForm: FC<React.PropsWithChildren<ContactFormProps>> = ({initialValues, onSubmit, validate, onCancel, initialStatus}) => {
    const [disableAccount] = useState(() => Boolean(initialValues?.account));
    const defaultInitialValues: Values = {
        email: undefined,
        firstName: '',
        lastName: '',
        phone: '',
        mobilePhone: '',
        type: '',
        account: '',
        externalId: '',
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialStatus={initialStatus}
            validate={validate}
            initialValues={{...defaultInitialValues, ...(initialValues || {})}}
        >
            <Form withBackdrop>
                <TextInput name='firstName' label={t`Name`} />
                <TextInput name='lastName' label={t`Last Name`} />
                <TextInput name='email' label={t`Email`} inputMode='email' />
                <ContactTypeAutocomplete name='type' />
                <TextInput name='mobilePhone' label={t`Mobile phone`} inputMode='tel' />
                <TextInput name='phone' label={t`Phone`} inputMode='tel' />
                {disableAccount
                    ? <AccountAutocomplete name='account' disabled={disableAccount} />
                    : <AccountAutocompleteWithCreate name='account' gridProps={{xs: 6}} />
                }
                <TextInput name='externalId' label={t`JDE ID`} />
                <FormErrors />
                <FormActions>
                    <SubmitButton label={t`Save`} submittingLabel={t`Saving...`} />
                    {onCancel && <Button onClick={onCancel}><Trans>Cancel</Trans></Button>}
                </FormActions>
            </Form>
        </Formik>
    );
};
