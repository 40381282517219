import {Square} from '@mui/icons-material';
import {Grid, Tooltip} from '@mui/material';
import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {EcnsEcnSeasonPriorityChoices} from 'generated/graphql';
import {FC} from 'react';
import {PRIORITY_COLORS} from 'settings';

export const useSeasonPriorityHelpers = () => {
    const translations = {
        [EcnsEcnSeasonPriorityChoices.Low]: {label: 'Baja', description: 'Este ECN puede o no estar efectivo para la próxima campaña'},
        [EcnsEcnSeasonPriorityChoices.Medium]: {label: 'Media', description: 'Tratamiento convencional del ECN'},
        [EcnsEcnSeasonPriorityChoices.High]: {label: 'Alta', description: 'Priorizar ECN, es critico que este ECN este efectivo para la próxima campaña'},
    };
    const options = [
        {label: translations[EcnsEcnSeasonPriorityChoices.Low].label, value: EcnsEcnSeasonPriorityChoices.Low.toLowerCase()},
        {label: translations[EcnsEcnSeasonPriorityChoices.Medium].label, value: EcnsEcnSeasonPriorityChoices.Medium.toLowerCase()},
        {label: translations[EcnsEcnSeasonPriorityChoices.High].label, value: EcnsEcnSeasonPriorityChoices.High.toLowerCase()},
    ];

    return {translations, options};
};


export const PriorityAutocomplete: FC<AutocompletePublicProps> = props => {
    const {options, translations} = useSeasonPriorityHelpers();

    return (
        <Autocomplete
            options={options}
            labelKey='label'
            valueKey='value'
            {...props}
            renderOption={(props, option) => (
                <Tooltip title={translations[option.value.toUpperCase() as keyof typeof translations].description || ''}>
                    <li {...props}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item>{option.label}</Grid>
                            <Grid item>
                                <Square color={PRIORITY_COLORS[option.value.toUpperCase() as keyof typeof PRIORITY_COLORS]} />
                            </Grid>
                        </Grid>
                    </li>
                </Tooltip>
            )}
        />
    );
};


export const useWarrantyPriorityHelpers = (asString: boolean | undefined = false) => {
    const getDescription = (value: boolean | string) => value === true || value === 'true'
        ? 'Priorizar ECN, afecta garantías de clientes'
        : 'Tratamiento convencional del ECN';

    const options = [
        {label: 'Si', value: asString ? 'true' : true},
        {label: 'No', value: asString ? 'false' : false},
    ];

    return {getDescription, options};
};


export const WarrantyPriorityAutocomplete: FC<{asString?: boolean;} & AutocompletePublicProps> = ({asString, ...props}) => {
    const {options, getDescription} = useWarrantyPriorityHelpers(asString);

    return (
        <Autocomplete
            options={options}
            labelKey='label'
            valueKey='value'
            label='Afecta a garantías'
            {...props}
            renderOption={(props, option) => (
                <Tooltip title={getDescription(option.value) || ''}>
                    <li {...props}>
                        {option.label}
                    </li>
                </Tooltip>
            )}
        />
    );
};
