import {t} from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from '@mui/material'
import {GraphQLErrors} from 'components/graphql-errors'
import {setErrors} from 'core/components/forms'
import {FormikHelpers, useFormikContext} from 'formik'
import {SeederForm, SeederFormProps, SeederFormValues} from 'forms/seeders/seeder-form'
import {
    CreateSeederMutationInput,
    GetSeederOptionsDocument,
    useCreateSeederMutation,
    useGetSeederLazyQuery,
} from 'generated/graphql'
import {useSnackbar} from 'notistack'
import {FC, useEffect} from 'react'
import {makeStyles} from 'tss-react/mui'

type SeederDialogFormProps = {
    open: boolean;
    edit?: boolean;
    seederFieldName?: string;
    accountId?: string | null;
    toggle: () => void;
} & Pick<SeederFormProps, 'initialValues'>;

export const SeederDialogForm: FC<React.PropsWithChildren<SeederDialogFormProps>> = ({
    open,
    edit,
    toggle,
    accountId,
    seederFieldName = 'seeder',
    initialValues,
}) => {
    const { classes } = useStyles();
    const [createSeeder] = useCreateSeederMutation();
    const {enqueueSnackbar} = useSnackbar();
    const {setFieldValue} = useFormikContext();
    const [getSeeder, {data: seederData, loading, error: queryError, refetch}] = useGetSeederLazyQuery({fetchPolicy: 'network-only'});

    useEffect(() => {
        initialValues?.id && getSeeder({variables: {id: initialValues.id}});
    }, [initialValues?.id]);

    const handleSubmit = async (
        values: SeederFormValues,
        actions: FormikHelpers<SeederFormValues>,
    ) => {
        actions.setSubmitting(true);
        const {data} = await createSeeder({
            variables: {input: values as CreateSeederMutationInput},
            awaitRefetchQueries: true,
            refetchQueries: [
                {query: GetSeederOptionsDocument, variables: {accountId: accountId}},
                {query: GetSeederOptionsDocument},
            ],
        });
        if (data?.createSeeder?.errors?.length === 0 && data.createSeeder.seeder) {
            setFieldValue(seederFieldName, data.createSeeder.seeder.id, true);
            enqueueSnackbar(t`Seeder created!`, {variant: 'success'});
            toggle();
        } else {
            setErrors(data?.createSeeder?.errors, actions);
        }
        actions.setSubmitting(false);
    };

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            onClose={toggle}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container={true} justifyContent='flex-end'>
                    <IconButton onClick={toggle} size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.dialogContent} item={true} xs={12}>
                    {queryError && <GraphQLErrors error={queryError} refetch={refetch} />}
                    {loading && <Grid container justifyContent='center'><CircularProgress color='primary' /></Grid>}
                    {accountId && (
                        <SeederForm
                            initialValues={{
                                account: accountId,
                                ...(seederData && edit && {
                                    ...initialValues,
                                    id: seederData.seeder.id,
                                    account: seederData.seeder.account.id,
                                    brand: seederData.seeder.brand.id,
                                    meterType: seederData.seeder.meterType.toLowerCase(),
                                    model: seederData.seeder.model?.id,
                                    rowCount: seederData.seeder.rowCount,
                                    year: seederData.seeder.year,
                                    installedProductCategories: seederData.seeder.installedProductCategories.map(({id}) => id)
                                } || initialValues)
                            }}
                            onSubmit={handleSubmit}
                            onCancel={toggle}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: 0,
    },

    dialogContent: {
        padding: theme.spacing(1),
    }
}));
