import {useGetRuntimeSettingsQuery} from 'generated/graphql'

export interface Hash<T> {
    [key: string]: T;
}

export const getObjectKeys = <T extends object>(value: T) => Object.keys(value) as (keyof T)[];
export const getObjectEntries = <T extends object>(value: T) => Object.entries(value) as ([keyof T, T[keyof T]])[];

export const getHash = (items: any[], key: string): Hash<any> => {
    return items.reduce((hash: Hash<any>, item: any) => {
        hash[item[key]] = item;
        return hash;
    }, {} as any);
};

export const useGetTaxIdLabel = () => {
    const {data} = useGetRuntimeSettingsQuery({fetchPolicy: 'cache-first'});
    return data?.runtimeSettings.taxIdLabel || 'Tax ID';
};

export const encodeToB64 = (object: any) => {
    return Buffer.from(JSON.stringify(object)).toString('base64');
};

export const decodeFromB64 = (value: string) => {
    return JSON.parse(Buffer.from(value, 'base64').toString());
};

export const openData = ({data, filename, contentType}: {data: string; filename: string; contentType: string;}) => {
    const link = document.createElement('a');
    link.href = `data:${contentType};filename=${filename};base64,${data}`;
    link.setAttribute('download', filename);
    link.click();
};

export const notEmpty = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null;

export const isUrlPdf = (url: string) => {
    return url.split('?').shift()?.endsWith('.pdf');
};
