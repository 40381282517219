import {ApolloProvider} from '@apollo/client';
import {CacheProvider, EmotionCache} from '@emotion/react';
import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import * as Sentry from '@sentry/node';
import {apolloClient} from 'api';
import Layout from 'components/layout';
import {SnackbarDownloadViaWorker} from 'components/notistack';
import {PageViewTrackerWithUserId} from 'components/page-view-tracker';
import {SetSentryUser} from 'components/set-sentry-user';
import {ExpiredQuotesProvider} from 'contexts/expired-quotes';
import {UserProvider} from 'contexts/user';
import createEmotionCache from 'create-emotion-cache';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import {loadCatalog, useLinguiInit} from 'i18n';
import {NextPageContext} from 'next';
import App, {AppContext, AppProps} from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {SnackbarProvider} from 'notistack';
import {useEffect} from 'react';
import * as settings from 'settings';
import {theme} from 'theme';

// Date & Time

dayjs.locale(settings.LANGUAGE_CODE.split('-')[0]);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);


const {publicRuntimeConfig} = getConfig();

Sentry.init({
    dsn: publicRuntimeConfig.sentryPublicDSN,
    release: publicRuntimeConfig.version,
    environment: publicRuntimeConfig.environment,
    debug: publicRuntimeConfig.environment === 'development',
});

export type CustomAppProps = AppProps & {
    err?: Error;
    emotionCache?: EmotionCache;
};

// Example https://github.com/mui/material-ui/blob/62e0748c47a11a9bd49bc74fb6293ac710936b15/examples/material-next/pages/_document.js

const clientSideEmotionCache = createEmotionCache();


const CustomApp = ({Component, pageProps, emotionCache = clientSideEmotionCache, err}: CustomAppProps) => {
    useLinguiInit(pageProps.translation);
    const router = useRouter();

    // useEffect(() => {
    //     // Delete the server-side injected CSS.
    //     const jssStyles = document.querySelector('#jss-server-side');
    //     if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles);
    // });

    // Workaround for https://github.com/zeit/next.js/issues/8592
    useLinguiInit(pageProps.translation);
    const modifiedPageProps = {...pageProps, err};
    useEffect(() => {
        const handler = (nextPath: string) => {
            localStorage.setItem('prevPath', router.asPath);
            localStorage.setItem('nextPath', nextPath);
        };
        router.events.on('beforeHistoryChange', handler);
        return () => router.events.off('beforeHistoryChange', handler);
    });
    return <CacheProvider value={emotionCache}>
        <Head>
            <title>{settings.SITE_NAME}</title>
            <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' />
        </Head>
        <StyledEngineProvider injectFirst>
            <ApolloProvider client={apolloClient}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider Components={{
                        downloadViaWorker: SnackbarDownloadViaWorker
                    }}>
                        <I18nProvider i18n={i18n} >
                            <UserProvider>
                                <CssBaseline />
                                <Layout>
                                    <ExpiredQuotesProvider>
                                        <Component {...modifiedPageProps} />
                                    </ExpiredQuotesProvider>
                                </Layout>
                                <PageViewTrackerWithUserId />
                                <SetSentryUser />
                            </UserProvider>
                        </I18nProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </ApolloProvider>
        </StyledEngineProvider>
    </CacheProvider>;
};

CustomApp.getInitialProps = async (appContext: NextPageContext & AppContext) => {
    const appProps = await App.getInitialProps(appContext);
    const translation = await loadCatalog(appContext.locale || settings.LANGUAGE_CODE);
    return {...appProps, pageProps: {...appProps.pageProps, translation}};
};


// export const getStaticProps = async (ctx: GetStaticPropsContext) => {
//     const translation = await loadCatalog(ctx.locale || 'es-ar')

//     console.log(translation)
//     return {
//         props: {
//             translation,
//         },
//     }
// }

export default CustomApp;
