import {Box, useMediaQuery} from '@mui/material';
import {GraphQLErrors} from 'components/graphql-errors';
import {useToggle} from 'core/hooks';
import {ExpiredQuotesModal} from 'forms/quotes/expired/expired-quotes-modal';
import {useGetExpiredQuotesCountQuery} from 'generated/graphql';
import {FC, PropsWithChildren} from 'react';

import {useUser} from './user';

export const ExpiredQuotesProvider: FC<PropsWithChildren> = ({children}) => {
    const print = useMediaQuery('print');
    const {user} = useUser();
    const {data, error, refetch} = useGetExpiredQuotesCountQuery({
        pollInterval: 1000 * 60 * 60,
        skip: !Boolean(user) || print,
        onCompleted: data => {
            if (data && data.expiredQuotes.count > 0) {
                setOpen();
            } else {
                setClose();
            }
        }
    });
    const {open, setClose, setOpen} = useToggle();

    if (print) return <>{children}</>;
    if (!user) return <>{children}</>;

    return (
        <>
            {error && (
                <Box mb={4}>
                    <GraphQLErrors error={error} refetch={refetch} />
                </Box>
            )}
            <ExpiredQuotesModal open={open} onClose={setClose} expiredAmount={data?.expiredQuotes.count} />
            {children}
        </ >
    );
};

