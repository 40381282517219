import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms/autocomplete-multiple';
import {useGetOrganizationsQuery} from 'generated/graphql';
import {FC} from 'react';

export const OemsAutocomplete: FC<AutocompleteMultiplePublicProps> = props => {
    const {data: {organizations} = {}, loading} = useGetOrganizationsQuery({variables: {options: {groupIsOemBool: ['true']}}});
    return (<AutocompleteMultiple
        loading={loading}
        options={organizations?.items.map(({id, name}) => ({id, name})) || []}
        valueKey='id'
        labelKey='name'
        {...props}
    />
    );
};
