import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {EcnsEcnNewPartTypeChoices} from 'generated/graphql';
import {FC} from 'react';
import {getObjectEntries} from 'utils';

const newPartTypeTranslations: Record<Lowercase<EcnsEcnNewPartTypeChoices>, string> = {
    commercial: 'Comercial',
    beta: 'Beta',
    internal_beta: 'Beta interna',
    tooling: 'Tooling'
};

export const newPartTypeAutocompleteOptions = getObjectEntries(newPartTypeTranslations).map(([value, label]) => ({value, label}));

export const NewPartTypeAutocomplete: FC<AutocompletePublicProps> = props => {
    return (
        <Autocomplete
            options={newPartTypeAutocompleteOptions}
            valueKey='value'
            labelKey='label'
            {...props}
        />
    );
};