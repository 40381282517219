import * as Apollo from '@apollo/client';
import {Alert, Button, CircularProgress, Stack} from '@mui/material';
import {useAsyncDownloadFile, UseAsyncDownloadFileProps} from 'core/hooks/use-async-download-file';
import {CustomContentProps, SnackbarContent, useSnackbar} from 'notistack';
import React, {ForwardedRef, ForwardRefRenderFunction, Ref, useEffect, useRef, useState} from 'react';

type SnackbarDownloadViaWorker<TData = any, TVariables = Apollo.OperationVariables> = CustomContentProps & {
    hookProps: UseAsyncDownloadFileProps<TData, TVariables>;
};

function SnackbarDownloadViaWorkerF<T extends HTMLDivElement = HTMLDivElement, TData = any, TVariables = Apollo.OperationVariables>(props: SnackbarDownloadViaWorker<TData, TVariables>, ref: Ref<T> | undefined) {
    const {id, message, action, hookProps, ...other} = props;
    const {closeSnackbar} = useSnackbar();
    const [exportJob, {loading, cancelFetch}] = useAsyncDownloadFile({
        ...hookProps, hideSnackbars: true,
        onCompleted: () => {
            closeSnackbar(id);
        }
    });

    useEffect(() => {
        exportJob();
    }, []);

    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <Alert
                action={
                    <Stack direction="row" spacing={2} justifyContent='space-between' alignItems='center'>
                        <CircularProgress color="primary" size={12} />
                        <Button
                            onClick={() => {
                                cancelFetch();
                                closeSnackbar(id);
                            }}
                            sx={{minWidth: 'fit-content'}}
                            size="small"
                            color="primary"
                            variant="text"
                        >
                            Cancelar
                        </Button>
                        {typeof action === 'function' ? action(id) : action}
                    </Stack>
                }
                severity="success"
                variant='outlined'
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    bgcolor: 'background.paper'
                }}
            >
                {message || 'Descargando...'}
            </Alert>
        </SnackbarContent>
    );
};

export const SnackbarDownloadViaWorker = React.forwardRef(SnackbarDownloadViaWorkerF);
