import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {EcnsEcnCancelledPartReplaceChoices} from 'generated/graphql';
import {FC} from 'react';

export const cancelledPartReplaceTypeAutocompleteOptions = [
    {value: EcnsEcnCancelledPartReplaceChoices.Immediate.toLowerCase(), label: 'Reemplazo inmediato/scrap'},
    {value: EcnsEcnCancelledPartReplaceChoices.UntilStockLast.toLowerCase(), label: 'Usar hasta agotar stock'},
    {value: EcnsEcnCancelledPartReplaceChoices.ToSegregate.toLowerCase(), label: 'Segregar y usar nuevo'},
];

export const CancelledPartReplaceTypeAutocomplete: FC<AutocompletePublicProps> = props => {
    return (
        <Autocomplete
            options={cancelledPartReplaceTypeAutocompleteOptions}
            valueKey='value'
            labelKey='label'
            {...props}
        />
    );
};
