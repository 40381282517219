import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
    AutocompleteProps,
    CircularProgress,
    Grid,
    TextField,
} from '@mui/material'
import {defaultGridProps, FormChild} from 'core/components/forms/form'
import {useDebounce} from 'core/hooks'
import {useField} from 'formik'
import {LocationOptionFragment, useGetLocationsLazyQuery} from 'generated/graphql'
import {FC, useEffect, useState} from 'react'

type SearchLocationAutocompleteProps<T = any> = FormChild & {
    name: string;
    label?: string;
    disabled?: boolean;
    disableClearable?: boolean;
} & Pick<AutocompleteProps<T, false, false, false>, 'size'>;


export const SearchLocationAutocomplete: FC<React.PropsWithChildren<SearchLocationAutocompleteProps>> = ({
    name,
    label,
    disabled,
    disableClearable,
    size,
    gridProps,
}) => {
    const [field, meta, helpers] = useField<string>(name);
    const [noResults, setNoResults] = useState(false);
    const [getLocations, {loading, data: {locations: options = []} = {}}] = useGetLocationsLazyQuery({
        onCompleted: (data) => setNoResults(!!data.locations?.length ? false : true),
    });

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        /** Handler for editing case */
        field.value && options.length === 0 && getLocations({variables: {id: Number(field.value)}});
    }, []);

    useEffect(() => {
        if (debouncedSearch) getLocations?.({variables: {query: debouncedSearch}});
    }, [debouncedSearch, getLocations]);

    const handleSearch = (search: string, reason: AutocompleteInputChangeReason) => {
        if (reason !== 'input') return setSearch('');
        setSearch(search);
    };

    const handleChange = (value: LocationOptionFragment | null, reason: AutocompleteChangeReason) => {
        if (reason === 'clear') helpers.setValue('');
        if (reason !== 'selectOption') return;
        if (value) {
            helpers.setValue(value.id);
        } else {
            helpers.setValue('');
        }
    };

    const component = (
        <Autocomplete<LocationOptionFragment, false, typeof disableClearable, false>
            disabled={disabled}
            value={options.find(({id}) => id === field.value) || null}
            onChange={(_e, value, reason) => handleChange(value, reason)}
            onInputChange={(_e, value, reason) => handleSearch(value, reason)}
            getOptionLabel={option => option.path || ''}
            getOptionDisabled={option => !option.path}
            options={loading ? [] : options}
            loading={loading}
            openOnFocus={true}
            multiple={false}
            blurOnSelect={true}
            loadingText='Cargando'
            filterOptions={options => options}
            noOptionsText={noResults ? 'Sin resultados' : 'Escriba una localidad'}
            size={size}
            fullWidth={true}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || 'Ubicación'}
                    variant='outlined'
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );

    if (!gridProps) return component;
    return <Grid item={true} {...gridProps}>{component}</Grid>;
};

SearchLocationAutocomplete.defaultProps = {
    gridProps: defaultGridProps,
};