import {Trans} from '@lingui/macro';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {Badge, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Theme, Typography} from '@mui/material';
import {GraphQLErrors} from 'components/graphql-errors';
import {NotificationMenuItem} from 'components/notifications';
import dayjs from 'dayjs';
import {
    GetNotificationsDocument,
    NotificationFragment,
    useGetNotificationsQuery,
    useMarkAllNotificationsAsReadMutation,
    useMarkNotificationAsReadMutation,
} from 'generated/graphql';
import {FC, useEffect, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

export const NotificationBell: FC = () => {
    const pageSize = 5;
    const pollInterval = 1000 * 60;
    const {classes} = useStyles();
    const [page, setPage] = useState(0);
    const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
    const [markAllNotificationsAsRead] = useMarkAllNotificationsAsReadMutation();
    const [notifications, setNotifications] = useState<NotificationFragment[]>([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const {data, loading, error, refetch, fetchMore} = useGetNotificationsQuery({
        variables: {options: {page: 0, pageSize: pageSize}},
        pollInterval: pollInterval,
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const unreadNotifications = data?.notifications.unreadCount;
    const allNotificationsLoaded = data?.notifications.notifications.count === notifications.length;

    const appendNotifications = (items: NotificationFragment[]) => {
        const filteredData = notifications.filter(notification => !items.some(item => item.id === notification.id));
        setNotifications([...items, ...filteredData].sort((a, b) => dayjs(b.created).diff(dayjs(a.created))));
    };

    useEffect(() => {
        if (data?.notifications.notifications.items) {
            appendNotifications(data.notifications.notifications.items);
            setPage(Math.floor(notifications.length / pageSize));
            if (firstLoad) setFirstLoad(false);
        }
    }, [data]);

    useEffect(() => {
        const fetch = async () => {
            const {data} = await fetchMore({variables: {options: {page: page, pageSize: pageSize}}});
            if (data?.notifications.notifications.items) {
                appendNotifications(data.notifications.notifications.items);
            }
        };
        if (!loading) fetch();
    }, [page]);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMarkNotificationAsRead = async (notification: NotificationFragment, index: number) => {
        const {data} = await markNotificationAsRead({
            variables: {input: {id: notification.id, isRead: true}},
            refetchQueries: [
                {query: GetNotificationsDocument, variables: {options: {page: Math.floor((index + 1) / pageSize), pageSize: pageSize}}},
            ],
        });
        const newNotification = data?.markNotificationAsRead?.notification;
        if (newNotification) {
            appendNotifications([newNotification]);
        }
    };

    const handleMarkAllNotificationsAsRead = async () => {
        const {data} = await markAllNotificationsAsRead({refetchQueries: [{query: GetNotificationsDocument, variables: {options: {page: 0, pageSize: page * pageSize}}}]});
        if (data?.markAllNotificationsAsRead?.status) {
            setNotifications(state => [...state.map(({isRead, ...rest}) => ({...rest, isRead: true}))]);
        }
    };

    return <>
        <IconButton onClick={handleOpen} size="large">
            <Badge badgeContent={unreadNotifications} color='error'>
                {!unreadNotifications
                    ? <NotificationsNoneOutlinedIcon color='primary' />
                    : <NotificationsActiveOutlinedIcon color='primary' />}
            </Badge>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{list: classes.menu}}
            keepMounted={true}>
            {firstLoad && !error && <CircularProgress color='primary' />}
            {!firstLoad && error && <GraphQLErrors error={error} refetch={refetch} />}
            {!firstLoad && !error && notifications.length > 0 && (<MenuItem divider={true}>
                <Grid container={true} justifyContent='space-between'>
                    <a href='/users/profile/notifications/edit' style={{textDecoration: 'none'}}>
                        <Button variant='text' color='primary' size='small'>
                            <Trans>
                                Edit notifications
                            </Trans>
                        </Button>
                    </a>
                    <Button onClick={handleMarkAllNotificationsAsRead} variant='text' color='primary' size='small'>
                        <Trans>
                            Mark all as read
                        </Trans>
                    </Button>
                </Grid>
            </MenuItem>)}
            {!firstLoad && !error && notifications.length > 0 && notifications.map((notification, index) =>
                <NotificationMenuItem
                    key={notification.id}
                    notification={notification}
                    onClick={() => handleMarkNotificationAsRead(notification, index)}
                />
            )}
            {!firstLoad && !error && notifications.length > 0 && !allNotificationsLoaded && (
                <MenuItem onClick={() => setPage(state => state + 1)}>
                    <Grid container={true} justifyContent='center'>
                        <Typography variant='subtitle2' color='primary' align='center'>
                            {loading ? <CircularProgress color='primary' /> : <Trans>Load more</Trans>}
                        </Typography>
                    </Grid>
                </MenuItem>
            )}
            {!firstLoad && !error && notifications.length === 0 && (
                <MenuItem>
                    <Grid container={true} justifyContent='center'>
                        <Typography variant='subtitle1' color='primary' align='center'>
                            <Trans>No pending notifications</Trans>
                        </Typography>
                    </Grid>
                </MenuItem>
            )}
        </Menu>
    </>;
};

const useStyles = makeStyles()((theme: Theme) => ({
    menu: {
        paddingTop: 0,
        paddingBottom: 0,
    }
}));
