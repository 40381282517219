import {Menu as MenuIcon} from '@mui/icons-material';
import {
    AppBar,
    Drawer,
    Hidden,
    IconButton,
    styled,
    SwipeableDrawer,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useScrollTrigger,
} from '@mui/material';
import {PartsManagementMenu} from 'components/layout/parts-management-menu';
import {AgcoLogo} from 'components/quotes/agco-logo';
import {useUser} from 'contexts/user';
import {formatDateTime} from 'core/locale';
import {useRouter} from 'next/router';
import React, {FC, useState} from 'react';
import * as settings from 'settings';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import AppMenu from './app-menu';
import UserMenu from './user-menu';

const drawerWidth = 260;


const useStyles = makeStyles<{elevateAppBar?: boolean, height?: number;}>()((theme, props) => ({
    root: {
        display: 'flex',
    },

    main: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
        background: '#3F4443',
        borderBottom: `${props.elevateAppBar ? 0 : 1}px solid ${theme.palette.divider}`,
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },

    logo: {
        flexGrow: 1,
        display: 'flex',
        marginTop: 4, // Adjust vertical alignment
        alignItems: 'center',
        cursor: 'pointer',
        '& img': {
            width: 'auto',
            height: props.height,
            [theme.breakpoints.down('md')]: {
                height: 20,
            }
        },
    },
    toolbarSpacer: {
        ...((theme.mixins.toolbar || {}) as CSSObject),
    },

    drawer: {
        width: `${drawerWidth}px`,
    },

    drawerPaper: {
        width: `${drawerWidth}px`,
    },

    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },

    printHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    drawerOpen: {
        width: `${drawerWidth}px`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingLeft: theme.spacing(1),
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        paddingLeft: theme.spacing(1),
    },

    warning: {
        color: theme.palette.secondary.main,
    },

    warningAppBar: {
        background: theme.palette.secondary.main,
        height: theme.spacing(1),
        border: 0,
    }
}));

interface LayoutProps {
    children: React.ReactNode;
}

interface LogoProps {
    color: 'grey' | 'white';
    height: number;
}

const Logo: FC<React.PropsWithChildren<LogoProps>> = ({color, height}) => {
    const {classes} = useStyles({height});
    const router = useRouter();
    const images = {
        grey: require('./assets/logo-horizontal-grey.svg'),
        white: require('./assets/logo-horizontal-white.svg'),
    };
    return (
        <div className={classes.logo} onClick={() => router.push('/')}>
            <img src={images[color]} alt={settings.SITE_NAME} />
            {settings.ENVIRONMENT !== 'production-ar' && (
                <Typography className={classes.warning}>
                    {settings.ENVIRONMENT || 'DEVELOPMENT'}
                </Typography>
            )}
        </div>
    );
};

const Layout: FC<React.PropsWithChildren<LayoutProps>> = ({children}) => {
    const [drawerOpened, setDrawerOpened] = useState(false);
    const elevateAppBar = useScrollTrigger({disableHysteresis: true, threshold: 0});
    const print = useMediaQuery('print');
    const {classes, cx} = useStyles({elevateAppBar});
    const agco = new URLSearchParams(window.location.search).get('agco');
    const {user} = useUser();
    const router = useRouter();
    const isPartsManagement = router.pathname.includes('parts-management');
    const isHome = router.pathname.includes('home');

    if (print) {
        return (
            <>
                <div className={classes.printHeader}>
                    <Logo color='grey' height={30} />
                    {agco ? <AgcoLogo /> : formatDateTime()}
                </div>
                {children}
            </>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} elevation={elevateAppBar ? 4 : 0}>
                <Toolbar>
                    {user && !isHome && !isPartsManagement && (
                        <IconButton
                            className={classes.menuButton}
                            edge='start'
                            color='primary'
                            onClick={() => setDrawerOpened(!drawerOpened)}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Logo color='white' height={40} />
                    {isPartsManagement && <PartsManagementMenu />}
                    <UserMenu />
                </Toolbar>
                {settings.ENVIRONMENT !== 'production-ar' && <AppBar className={classes.warningAppBar} position='sticky'></AppBar>}
            </AppBar>
            {user && !isHome && !isPartsManagement && (
                <>
                    <Hidden mdUp={true}>
                        <SwipeableDrawer
                            className={classes.drawer}
                            classes={{paper: classes.drawerPaper}}
                            keepMounted={true}
                            open={drawerOpened}
                            onOpen={() => setDrawerOpened(true)}
                            onClose={() => setDrawerOpened(false)}>
                            <Toolbar></Toolbar>
                            <AppMenu onClose={() => setDrawerOpened(false)} />
                        </SwipeableDrawer>
                    </Hidden>
                    <Hidden mdDown={true} implementation='css'>
                        <DesktopDrawer variant="permanent" open={drawerOpened}>
                            <Toolbar />
                            <AppMenu />
                        </DesktopDrawer>
                    </Hidden>
                </>
            )}
            <main className={classes.main}>
                <Toolbar />
                {children}
            </main>
        </div>
    );
};

export default Layout;



const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DesktopDrawer = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
