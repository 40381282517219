import {Trans, t} from '@lingui/macro';
import {Grid, MenuItem, TextField} from '@mui/material';
import {
    AutocompleteMultiple,
    type AutocompleteMultiplePublicProps,
    type AutocompletePublicProps,
} from 'core/components/forms';
import {Field, type FieldProps} from 'formik';
import {SupplyOrdersSupplyOrderStatusChoices} from 'generated/graphql';
import type {FC} from 'react';

export const SupplyStateAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = props => {
    return (
        <Grid item={true} {...props.gridProps}>
            <Field name={props.name}>
                {({field}: FieldProps) => (
                    <TextField fullWidth={true} variant='outlined' select={true} label={t`Supply state`} {...field}>
                        <MenuItem value={SupplyOrdersSupplyOrderStatusChoices.Approved}>
                            <Trans>Approved</Trans>
                        </MenuItem>
                        <MenuItem value={SupplyOrdersSupplyOrderStatusChoices.Draft}>
                            <Trans>Draft</Trans>
                        </MenuItem>
                        <MenuItem value={SupplyOrdersSupplyOrderStatusChoices.SalesReview}>
                            <Trans>Sales review</Trans>
                        </MenuItem>
                        <MenuItem value={SupplyOrdersSupplyOrderStatusChoices.InProgress}>
                            <Trans>In progress</Trans>
                        </MenuItem>
                        <MenuItem value={SupplyOrdersSupplyOrderStatusChoices.Finished}>
                            <Trans>Confirmed</Trans>
                        </MenuItem>
                        <MenuItem value={SupplyOrdersSupplyOrderStatusChoices.OnHold}>
                            <Trans>On hold</Trans>
                        </MenuItem>
                    </TextField>
                )}
            </Field>
        </Grid>
    );
};

export const SupplyStateAutocompleteMultiple: FC<React.PropsWithChildren<AutocompleteMultiplePublicProps>> = props => {
    const options = [
        {
            value: SupplyOrdersSupplyOrderStatusChoices.Approved.toLowerCase(),
            label: t`Approved`,
        },
        {
            value: SupplyOrdersSupplyOrderStatusChoices.Draft.toLowerCase(),
            label: t`Draft`,
        },
        {
            value: SupplyOrdersSupplyOrderStatusChoices.SalesReview.toLowerCase(),
            label: t`Sales review`,
        },
        {
            value: SupplyOrdersSupplyOrderStatusChoices.InProgress.toLowerCase(),
            label: t`In progress`,
        },
        {
            value: SupplyOrdersSupplyOrderStatusChoices.Finished.toLowerCase(),
            label: t`Confirmed`,
        },
        {
            value: SupplyOrdersSupplyOrderStatusChoices.OnHold,
            label: t`On hold`,
        },
    ];

    return (
        <AutocompleteMultiple options={options} valueKey='value' labelKey='label' label={t`Supply state`} {...props} />
    );
};
