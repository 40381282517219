import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms/autocomplete-multiple';
import {useGetPartsNumbersQuery} from 'generated/graphql';
import {FC} from 'react';


export const EcnPartNumbersAutocompleteMultiple: FC<AutocompleteMultiplePublicProps> = props => {
    const {data, loading} = useGetPartsNumbersQuery({fetchPolicy: 'cache-and-network'});

    return (
        <AutocompleteMultiple
            options={data?.partsNumbers.map(({externalId, displayName}) => ({externalId, displayName})) || []}
            loading={loading}
            valueKey='externalId'
            labelKey='displayName'
            label='Partes'
            {...props}
        />
    );
};
