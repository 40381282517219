import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {useGetProductOriginsQuery} from 'generated/graphql';
import {FC} from 'react';


export const ProductOriginAutocomplete: FC<AutocompletePublicProps> = props => {
    const {data: {productOrigins} = {}, loading} = useGetProductOriginsQuery();

    return (
        <Autocomplete
            options={productOrigins || []}
            loading={loading}
            valueKey='id'
            labelKey='name'
            {...props}
        />
    );
};
