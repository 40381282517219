import {ApolloError} from '@apollo/client';
import {Alert, AlertTitle, Button} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

interface GraphQLErrorsProps {
    error: ApolloError;
    refetch?: () => any;
}

export const GraphQLErrors: FC<React.PropsWithChildren<GraphQLErrorsProps>> = ({error, refetch}) => {
    const {classes} = useStyles();

    const refetchButton = refetch && (
        <Button color='inherit' size='small' onClick={() => refetch()}>
            Retry
        </Button>
    );

    return (
        <div className={classes.root}>
            {error.graphQLErrors.map(({message}) => (
                <Alert key={message} severity='error' action={refetchButton}>
                    <AlertTitle>GraphQL error</AlertTitle>
                    {message}
                </Alert>
            ))}
            {error.networkError && (
                <Alert severity='error' action={refetchButton}>
                    <AlertTitle>Network error</AlertTitle>
                    {error.networkError.message}
                </Alert>
            )}
        </div>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));
