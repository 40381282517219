import {MouseEvent, useCallback, useState} from 'react';

export const useToggle = (initialValue = false) => {
    const [state, setState] = useState(initialValue);

    const toggle = useCallback(
        (value?: boolean | MouseEvent | {}) =>
            setState(curr => (value !== undefined && typeof value === 'boolean' ? value : !curr)),
        [],
    );
    const setOpen = useCallback(() => setState(true), []);
    const setClose = useCallback(() => setState(false), []);

    return {open: state, toggle, setOpen, setClose};
};
