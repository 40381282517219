import {Trans} from '@lingui/macro';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography} from '@mui/material';
import {GraphQLErrors} from 'components/graphql-errors';
import {useToggle} from 'core/hooks';
import {useGetExpiredQuotesQuery} from 'generated/graphql';
import React, {FC} from 'react';

import {ExpiredQuotesForm} from './expired-quotes-form';


interface ExpiredQuotesModalProps {
    open: boolean;
    onClose: () => void;
    expiredAmount?: number;
}

export const ExpiredQuotesModal: FC<ExpiredQuotesModalProps> = ({open, onClose, expiredAmount}) => {
    const {open: confirmOpen, toggle: toggleConfirmOpen} = useToggle();
    const {open: formOpen, toggle: toggleForm} = useToggle();
    const {data, error, loading, refetch} = useGetExpiredQuotesQuery({
        skip: !formOpen,
    });
    const quotes = data?.expiredQuotes.items;

    const onSubmit = () => onClose();
    const onCancel = () => onClose();

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            fullWidth={formOpen}
            onClose={onClose}
        >
            <DialogContent>
                <Grid
                    container={true}
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing={2}>
                    <Grid item={true} xs={12}>
                        <Typography variant='h6'>
                            <Trans>Expired quotes</Trans>
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        {!confirmOpen && (
                            <>
                                <Typography variant='body2'>
                                    <Trans>You have {quotes?.length} quote/s expired.</Trans>
                                </Typography>
                                <Typography variant='body2'>
                                    <Trans>Please select a reason for each one for closing it.</Trans>
                                </Typography>
                            </>
                        )}
                        {loading && <CircularProgress color='primary' />}
                        {error && <GraphQLErrors error={error} refetch={refetch} />}
                        {formOpen && quotes && <ExpiredQuotesForm quotes={quotes} onSubmit={onSubmit} onCancel={onCancel} />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {!confirmOpen && (
                    <>
                        <Button onClick={onClose}>
                            <Trans>Close</Trans>
                        </Button>
                        <Button onClick={() => {
                            toggleConfirmOpen();
                            toggleForm();
                        }} color='primary'>
                            <Trans>Continue</Trans>
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};
