import {Grid, InputBaseProps, TextField, TextFieldProps} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import numbro from 'numbro';
import React, {FC, useEffect, useState} from 'react';
import * as settings from 'settings';

import {defaultGridProps, FormChild} from './form';

type BaseNumberInputProps = {
    name: string;
    inputMode?: 'numeric' | 'decimal';
    allowNegative?: boolean;
    maxDecimals?: number;
    maxNumber?: number;
};

export type NumberInputProps = FormChild
    & BaseNumberInputProps
    & Pick<TextFieldProps, 'sx' | 'label' | 'disabled' | 'placeholder' | 'size' | 'className' | 'classes' | 'fullWidth' | 'InputLabelProps'>
    & Pick<InputBaseProps, 'startAdornment' | 'endAdornment'>;

export const NumberInput: FC<React.PropsWithChildren<NumberInputProps>> = ({
    name,
    inputMode,
    allowNegative,
    maxDecimals,
    maxNumber,
    disabled,
    startAdornment,
    endAdornment,
    gridProps,
    fullWidth = true,
    ...otherProps
}) => {
    const {isSubmitting} = useFormikContext();
    const [field, meta, helpers] = useField<number | null>(name);
    const [stringValue, setStringValue] = useState(field.value ? field.value.toString() : '');

    // Handle value changes performed outside of the field.
    useEffect(() => {
        const newStringValue = (!field.value && field.value !== 0)
            ? ''
            : numbro(maxNumber && field.value > maxNumber ? maxNumber : field.value).format({
                thousandSeparated: false,
                trimMantissa: true,
                mantissa: 10,
            });
        if (newStringValue !== stringValue) {
            setStringValue(newStringValue);
        }
    }, [field.value]);

    const InputProps: Partial<InputBaseProps> = {};
    if (startAdornment) InputProps.startAdornment = startAdornment;
    if (endAdornment) InputProps.endAdornment = endAdornment;

    const component = (
        <TextField
            variant='outlined'
            fullWidth={fullWidth}
            disabled={isSubmitting || disabled}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            inputProps={{inputMode}}
            InputProps={InputProps}
            name={name}
            value={stringValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const {delimiters} = numbro.languageData();
                let newStringValue = event.target.value;

                // Manually remove the thousands delimiter to avoid confusion with the decimal delimiter.
                // Otherwise numbro will convert '1.5' to 15 and this is probably not what the user wants.
                newStringValue = newStringValue.replace(delimiters.thousands, '');

                // Delete minus sign if negative numbers are not allowed.
                if (!allowNegative) {
                    newStringValue = newStringValue.replace('-', '');
                }

                // Numeric input mode doesn't allow decimals, so remove the decimal delimiter.
                if (inputMode === 'numeric') {
                    newStringValue = newStringValue.replace(delimiters.decimal, '');
                }

                // Limit the number of decimal places.
                const [integer, decimals = ''] = newStringValue.split(delimiters.decimal);
                if (decimals.length > (maxDecimals as number)) {
                    newStringValue = `${integer}${delimiters.decimal}${decimals.substr(0, maxDecimals)}`;
                }

                // Convert to integer value using numbro's configured locale.
                let value = numbro.unformat(newStringValue);

                // If we set a maximum value, and the value is greatter than it
                // We set the value to the max number and the string value also.
                if (maxNumber && value > maxNumber) {
                    value = maxNumber;
                    newStringValue = maxNumber.toString();
                }

                // Disallow typing letters or other non numeric characters by only updating the internal state
                // when value is a valid number or some other special cases:
                // > Allow an empty string to clear the input, this will set value === null.
                // > When typing a negative number the first thing the user will probably type is the '-' sign,
                //   but this is not yet a valid number, so we have to manually allow it.
                if (value !== undefined || ['', '-'].includes(newStringValue)) {
                    setStringValue(newStringValue);
                }
                // Always set the value within formik.
                helpers.setValue(value !== undefined ? value : null);
            }}
            onBlur={field.onBlur}
            {...otherProps}
        />
    );

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
};

NumberInput.defaultProps = {
    inputMode: 'numeric',
    allowNegative: false,
    maxDecimals: Number(settings.MAX_DECIMALS),
    gridProps: defaultGridProps,
};
