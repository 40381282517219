import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms/autocomplete-multiple';
import {useGetUsersListQuery} from 'generated/graphql';
import {FC} from 'react';


export const EcnUserAutocompleteMultiple: FC<AutocompleteMultiplePublicProps> = (props) => {
    const {data, loading} = useGetUsersListQuery({variables: {options: {hasEcnRole: ['true']}}});

    return (
        <AutocompleteMultiple
            options={data?.usersList.items.map(({displayName, id}) => ({id, displayName})) || []}
            loading={loading}
            valueKey='id'
            labelKey='displayName'
            label='Usuarios'
            {...props}
        />
    );
};
