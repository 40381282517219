import {t} from '@lingui/macro'
import {Autocomplete, AutocompletePublicProps} from 'core/components/forms'
import {ProductsWarrantyClaimInspectionReviewStatusChoices} from 'generated/graphql'
import {FC} from 'react'


export const InpectionStateAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const options = [
        {id: '', name: t`All`},
        {id: 'null', name: t`Not received`},
        {id: ProductsWarrantyClaimInspectionReviewStatusChoices.Received, name: t`Received`},
        {id: ProductsWarrantyClaimInspectionReviewStatusChoices.Finished, name: t`Finished`},
    ];

    return (
        <Autocomplete
            options={options}
            valueKey='id'
            labelKey='name'
            label={t`Inspection state`}
            {...props}
        />
    );
};
