import {t, Trans} from '@lingui/macro'
import {Button, Checkbox, FormControlLabel, Grid, Typography} from '@mui/material'
import {AccountAutocomplete, SeederBrandAutocomplete, SeederModelAutocomplete} from 'components/autocompletes'
import {Autocomplete, Form, FormActions, FormErrors, NumberInput, SubmitButton} from 'core/components/forms'
import {ArrayHelpers, FieldArray, Formik, FormikErrors, FormikHelpers, FormikProps, setIn} from 'formik'
import {
    CreateSeederMutationInput,
    ProductCategoryOptionFragment,
    SeedersSeederMeterTypeChoices,
    UpdateSeederMutationInput,
    useGetProductCategoriesEnabledForSeederQuery,
} from 'generated/graphql'
import React, {ChangeEvent, FC} from 'react'
import {validateRequired} from 'utils/validators'


export type SeederFormValues = Omit<CreateSeederMutationInput | UpdateSeederMutationInput, 'rowCount' | 'year' | 'installedProductCategories'>
    & {rowCount: number | null;}
    & {installedProductCategories: string[];}
    & {year: number | null;};

export interface SeederFormProps {
    initialValues?: Partial<SeederFormValues>;
    onSubmit(values: SeederFormValues, actions: FormikHelpers<SeederFormValues>): void;
    onCancel?(): void;
}

export const SeederForm: FC<React.PropsWithChildren<SeederFormProps>> = ({initialValues, onSubmit, onCancel}) => {
    const defaultInitialValues: SeederFormValues = {
        account: '',
        brand: '',
        model: '',
        year: null,
        rowCount: null,
        meterType: '',
        installedProductCategories: [],
    };
    const meterTypeOptions = [
        {label: t`Pneumatic`, value: SeedersSeederMeterTypeChoices.Pneumatic.toLowerCase()},
        {label: t`Mechanic`, value: SeedersSeederMeterTypeChoices.Mechanic.toLowerCase()}
    ];
    const {data} = useGetProductCategoriesEnabledForSeederQuery();

    const createChangeHandler = (
        id: ProductCategoryOptionFragment['id'],
        categories: SeederFormValues['installedProductCategories'],
        helpers: ArrayHelpers,
    ) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.currentTarget.checked) return helpers.push(id);
            return helpers.remove(categories.indexOf(id));
        };
    };

    const renderCategory = (
        category: ProductCategoryOptionFragment,
        categories: SeederFormValues['installedProductCategories'],
        arrayHelpers: ArrayHelpers,
    ) => {
        const onChange = createChangeHandler(category.id, categories, arrayHelpers);
        const checked = categories.includes(category.id);
        const control = <Checkbox checked={checked} name={category.id} onChange={onChange} />;
        return (
            <Grid key={category.id} item={true} xs={12} sm={4} md={3}>
                <FormControlLabel label={category.name} control={control} />
            </Grid>
        );
    };

    const handleSubmit = (values: SeederFormValues, actions: FormikHelpers<SeederFormValues>) => {
        onSubmit(values, actions);
    };

    const validate = (values: SeederFormValues) => {
        let errors: FormikErrors<SeederFormValues> = {};
        ['year', 'rowCount', 'brand', 'model', 'meterType'].forEach(field => {
            const result = validateRequired(values[field as keyof SeederFormValues]);
            if (result !== true) errors = setIn(errors, field, result);
        });
        return errors;
    };

    return (
        <Formik
            validate={validate}
            onSubmit={handleSubmit}
            initialValues={{...defaultInitialValues, ...(initialValues || {})}}>
            {(formikProps: FormikProps<SeederFormValues>) => {
                return (
                    <Form withBackdrop>
                        <FormErrors />
                        <AccountAutocomplete name='account' disabled />
                        <SeederBrandAutocomplete name='brand' />
                        <SeederModelAutocomplete name='model' seederBrandFieldName='brand' />
                        <NumberInput
                            name='year'
                            inputMode='numeric'
                            size='medium'
                            label={t`Year`}
                            gridProps={{md: 3, xs: 12}}
                        />
                        <NumberInput
                            name='rowCount'
                            inputMode='numeric'
                            size='medium'
                            label={t`Row count`}
                            gridProps={{md: 3, xs: 12}}
                        />
                        <Autocomplete
                            name='meterType'
                            label={t`Dispenser type`}
                            valueKey='value'
                            labelKey='label'
                            options={meterTypeOptions}
                            gridProps={{md: 3, xs: 12}}
                        />
                        {!!data?.productCategoriesEnabledForSeeder.length && (
                            <Grid item={true} xs={12}>
                                <Typography variant='h6'><Trans>Available categories</Trans></Typography>
                                <FieldArray name='installedProductCategories'>
                                    {arrayHelpers => (
                                        <Grid container={true} spacing={1}>
                                            {data?.productCategoriesEnabledForSeeder.map(category => (
                                                renderCategory(
                                                    category,
                                                    formikProps.values.installedProductCategories,
                                                    arrayHelpers,
                                                )
                                            ))}
                                        </Grid>
                                    )}
                                </FieldArray>
                            </Grid>
                        )}
                        <FormActions>
                            <SubmitButton label={t`Save`} submittingLabel={t`Saving...`} />
                            {onCancel && <Button onClick={onCancel}><Trans>Cancel</Trans></Button>}
                        </FormActions>
                    </Form>
                );
            }}
        </Formik>
    );
};
