import {
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
    Button,
    createFilterOptions,
    Grid,
    TextField,
} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {useMemo} from 'react';

import {defaultGridProps, FormChild} from './form';

type BaseAutocompleteMultipleProps<T> = {
    valueKey: keyof T;
    labelKey: keyof T;
};

const ALL_KEY = '__all__';

export type AutocompleteMultiplePublicProps<T extends object = any> = FormChild & {
    name: string;
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    submitOnSelect?: boolean;
    disableClearable?: boolean;
    required?: boolean;
} & Pick<MUIAutocompleteProps<T, false, false, false>, 'size' | 'sx' | 'renderOption' | 'limitTags' | 'getOptionLabel'>;


type AutocompleteMultipleProps<T extends object> = FormChild
    & BaseAutocompleteMultipleProps<T>
    & AutocompleteMultiplePublicProps<T>
    & Pick<MUIAutocompleteProps<T, false, false, false>, 'options'>;

export function AutocompleteMultiple<T extends {[key: string]: string | undefined | object | number | boolean;}>(props: AutocompleteMultipleProps<T>) {
    const {
        name,
        label,
        disabled,
        loading,
        submitOnSelect,
        options,
        disableClearable,
        valueKey,
        labelKey,
        gridProps,
        required,
        size,
        sx,
        renderOption,
        getOptionLabel,
        limitTags = 3,
    } = props;

    const {isSubmitting, setFieldValue, submitForm} = useFormikContext();
    const [field, meta] = useField<string[]>(name);
    const value = options.filter(option =>
        (field.value || []).includes(option[valueKey] as string)
    ) as T[];

    // All props will trigger a re-render.
    const memoDeps = Object.keys(props)
        .sort()
        .map(key => (props as any)[key])
        .concat([value, isSubmitting]);

    const component = useMemo(() => (
        <MUIAutocomplete<T, true, typeof disableClearable, false>
            value={value}
            options={options}
            sx={sx}
            limitTags={limitTags}
            size={size}
            isOptionEqualToValue={(option, selected) => option[valueKey] === selected[valueKey]}
            multiple={true}
            blurOnSelect={true}
            disableClearable={disableClearable}
            disabled={isSubmitting || disabled}
            loading={loading}
            renderOption={(props, option, state) => {
                if (option[valueKey] === ALL_KEY) return <li {...props}><Button variant='outlined' fullWidth={true}>{option[labelKey]?.toString()}</Button></li>;
                if (renderOption) return renderOption(props, option, state);
                return <li {...props}>{option[labelKey]?.toString()}</li>;
            }}
            getOptionLabel={getOptionLabel ? getOptionLabel : option => option[labelKey] as any}
            openOnFocus={true}
            filterOptions={createFilterOptions({stringify: option => option[labelKey]!.toString()})}
            onChange={(_event: any, options: T[]) => {
                // When this issue is merged we can pass shouldValidate
                // directly to helpers.setValue and helpers.setTouched.
                // https://github.com/jaredpalmer/formik/pull/2371
                if (options.some(value => value[valueKey] === ALL_KEY)) {
                    setFieldValue(name, options.map(option => option[valueKey]), true);
                } else {
                    setFieldValue(name, options.map(value => value[valueKey]), true);
                }
                if (submitOnSelect) submitForm();
            }}
            onBlur={() => {
                // FIXME: The error is persisted and you have to trigger the blur or change event again
                // to actually clear the previous error.
                // helpers.setTouched(true, true);
                // setFieldTouched(name, true, true);
            }}
            renderInput={params => (
                <TextField
                    variant='outlined'
                    label={label}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...params}
                    InputLabelProps={{...params.InputLabelProps, required}}
                />
            )}
        />
    ), memoDeps);

    if (!gridProps) return component;

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    );
}

AutocompleteMultiple.defaultProps = {
    gridProps: defaultGridProps,
};
