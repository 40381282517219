import {Autocomplete, AutocompletePublicProps} from 'core/components/forms/autocomplete';
import {AutocompleteMultiple, AutocompleteMultiplePublicProps} from 'core/components/forms/autocomplete-multiple';
import {EcnsEcnPartTypeChoices} from 'generated/graphql';
import {FC} from 'react';

export const ecnPartTypeChoicesTranslations = {
    [EcnsEcnPartTypeChoices.NewPart]: 'Nueva pieza',
    [EcnsEcnPartTypeChoices.PartReplacement]: 'Reemplazo de pieza',
    [EcnsEcnPartTypeChoices.PartRevision]: 'Revisión de pieza',
    [EcnsEcnPartTypeChoices.PartCancelation]: 'Cancelación de pieza',
};

const options = [
    {value: EcnsEcnPartTypeChoices.NewPart.toLowerCase(), label: ecnPartTypeChoicesTranslations[EcnsEcnPartTypeChoices.NewPart]},
    {value: EcnsEcnPartTypeChoices.PartReplacement.toLowerCase(), label: ecnPartTypeChoicesTranslations[EcnsEcnPartTypeChoices.PartReplacement]},
    {value: EcnsEcnPartTypeChoices.PartRevision.toLowerCase(), label: ecnPartTypeChoicesTranslations[EcnsEcnPartTypeChoices.PartRevision]},
    {value: EcnsEcnPartTypeChoices.PartCancelation.toLowerCase(), label: ecnPartTypeChoicesTranslations[EcnsEcnPartTypeChoices.PartCancelation]},
];

export const EcnPartTypeAutocompleteMultiple: FC<AutocompleteMultiplePublicProps> = props => {

    return (
        <AutocompleteMultiple
            options={options}
            valueKey='value'
            labelKey='label'
            {...props}
            label='Tipos de ECNs'
        />
    );
};

export const EcnPartTypeAutocomplete: FC<AutocompletePublicProps> = props => {
    return (
        <Autocomplete
            options={options}
            valueKey='value'
            labelKey='label'
            {...props}
            label='Tipos de ECNs'
        />
    );
};
