import {t} from '@lingui/macro'
import {Autocomplete, AutocompletePublicProps} from 'core/components/forms'
import {useGetCloseReasonsQuery} from 'generated/graphql'
import {FC} from 'react'


export const CloseReasonAutocomplete: FC<React.PropsWithChildren<AutocompletePublicProps>> = (props) => {
    const {data, loading} = useGetCloseReasonsQuery();

    return (
        <Autocomplete
            options={data?.closeReasons || []}
            loading={loading}
            valueKey='description'
            labelKey='description'
            label={t`Close reason`}
            {...props}
        />
    );
};
