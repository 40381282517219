import queryString from 'query-string';
import {Dispatch, SetStateAction, useState} from 'react';

type Values = Record<string, string | string[]>;

const isSSR = () => typeof window === 'undefined';

export function useQueryStringState<T extends Values>(
    initialState: T,
): [T, Dispatch<SetStateAction<T>>] {
    // Merge initial querystring into initialState.
    const currentQueryString = queryString.parse(isSSR() ? '' : window.location.search);
    const mergedInitialState = Object.entries(initialState)
        .reduce((values, [key, value]) => ({
            ...values,
            [key]: currentQueryString[key] || value,
        }), {} as T);

    // Define inner useState hook.
    const [state, setState] = useState(mergedInitialState);

    // Define setState wrapper that will replicate state into querystring.
    const setStateWrapper:Dispatch<SetStateAction<T>> = value => {
        const newQueryString = queryString.parse(window.location.search);
        Object.keys(value).forEach(key => {
            newQueryString[key] = (value as Values)[key];
        });
        if (!isSSR()) window.history.replaceState(null, '', `?${queryString.stringify(newQueryString)}`);
        return setState(value);
    };

    return [state, setStateWrapper];
}
