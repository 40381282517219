import {SvgIcon} from '@mui/material'
import {FC} from 'react'

export const PemIcon: FC = props => {
    return (
        <SvgIcon {...props} viewBox='0 0 72 52' width='72' height='52'>
            <path d="M60.0211 29.5811C62.9996 29.5811 65.7219 30.4778 68.0279 32.0151V16.7701C68.0279 13.2471 65.1454 10.3646 61.6224 10.3646H41.4772L38.0823 6.96974L42.5982 2.45388L40.3242 0.179932L29.0186 11.4856L31.2925 13.7595L35.8084 9.24368L39.2033 12.6386V19.9728C39.2033 23.4959 36.3208 26.3783 32.7978 26.3783H31.0683C34.2389 29.8925 35.9959 34.4562 36.0005 39.1893C36.0005 40.2782 35.8724 41.3351 35.7123 42.392H45.7689C46.5696 35.1859 52.6227 29.5811 60.0211 29.5811Z" />
            <path d="M60.0202 29.5809C53.8389 29.5809 48.8106 34.6092 48.8106 40.7905C48.8106 46.9718 53.8389 52.0001 60.0202 52.0001C66.2015 52.0001 71.2298 46.9718 71.2298 40.7905C71.2298 34.6092 66.2015 29.5809 60.0202 29.5809ZM60.0202 45.5946C57.3619 45.5946 55.2161 43.4488 55.2161 40.7905C55.2161 38.1322 57.3619 35.9864 60.0202 35.9864C62.6784 35.9864 64.8243 38.1322 64.8243 40.7905C64.8243 43.4488 62.6784 45.5946 60.0202 45.5946ZM10.3777 16.77H26.3914C26.3914 13.247 23.509 10.3645 19.986 10.3645H10.3777C8.61624 10.3645 7.17501 11.8057 7.17501 13.5672C7.17501 15.3287 8.61624 16.77 10.3777 16.77ZM29.0497 32.2072L28.4732 30.7019L31.4518 29.5809C29.9729 26.2005 27.3547 23.4463 24.0534 21.7983L22.7723 24.6487L21.2991 23.9761L22.5802 21.0937C20.8187 20.3891 18.833 19.9727 16.7832 19.9727C15.0858 19.9727 13.4524 20.325 11.9151 20.8054L13.004 23.7199L11.4987 24.2964L10.3777 21.3179C6.99735 22.7967 4.24311 25.415 2.59509 28.7162L5.44553 29.9973L4.77295 31.4705L1.89049 30.1894C1.18589 31.9509 0.769531 33.9366 0.769531 35.9864C0.769531 37.6838 1.12183 39.3172 1.60224 40.8546L4.51673 39.7656L5.09323 41.2709L2.11468 42.3919C3.59355 45.7723 6.21177 48.5265 9.513 50.1745L10.7941 47.3241L12.2674 47.9967L10.9863 50.8791C12.7478 51.5837 14.7335 52.0001 16.7832 52.0001C18.4807 52.0001 20.1141 51.6478 21.6514 51.1674L20.5624 48.2529L22.0677 47.6764L23.1887 50.6549C26.5691 49.1761 29.3233 46.5578 30.9713 43.2566L28.1209 41.9755L28.7935 40.5023L31.6759 41.7834C32.3805 40.0218 32.7969 38.0362 32.7969 35.9864C32.7969 34.2889 32.4446 32.6556 31.9642 31.1182L29.0497 32.2072V32.2072ZM20.4664 44.858C15.5662 46.8757 9.92936 44.5697 7.91164 39.6695C5.89391 34.7694 8.19988 29.1325 13.1001 27.1148C18.0003 25.0971 23.6371 27.4031 25.6548 32.3033C27.7046 37.2034 25.3666 42.8403 20.4664 44.858V44.858Z" />
        </SvgIcon>
    );
};
