import {InternalRefetchQueriesInclude} from '@apollo/client';
import {t} from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import {CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from '@mui/material';
import {GraphQLErrors} from 'components/graphql-errors';
import {setErrors} from 'core/components/forms';
import {FormikHelpers, useFormikContext} from 'formik';
import {ContactForm, ContactFormProps} from 'forms/contacts/contact-form';
import {
    CreateContactMutationInput,
    GetContactOptionsDocument,
    useCreateContactMutation,
    useGetContactQuery,
} from 'generated/graphql';
import {useSnackbar} from 'notistack';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

type ContactDialogFormProps = {
    open: boolean;
    edit?: boolean;
    contactFieldName?: string;
    accountId?: string | null;
    toggle: () => void;
    validate?: ContactFormProps['validate'];
    initialStatus?: ContactFormProps['initialStatus'];
    refetchQueries?: InternalRefetchQueriesInclude;
} & Pick<ContactFormProps, 'initialValues'>;

export const ContactDialogForm: FC<React.PropsWithChildren<ContactDialogFormProps>> = ({
    open,
    edit,
    accountId,
    toggle,
    contactFieldName = 'contact',
    initialValues,
    initialStatus,
    validate,
    refetchQueries = [],
}) => {
    const {classes} = useStyles();
    const [createContact, {error}] = useCreateContactMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {query: GetContactOptionsDocument},
            {query: GetContactOptionsDocument, variables: {options: {account: [accountId]}}},
            ...refetchQueries,
        ]
    });
    const {enqueueSnackbar} = useSnackbar();
    const {setFieldValue} = useFormikContext();
    const {data: contactData, loading, error: queryError, refetch} = useGetContactQuery({
        fetchPolicy: 'network-only',
        skip: !Boolean(initialValues?.id),
        variables: {id: initialValues!.id}
    });

    const handleSubmit = async (
        values: CreateContactMutationInput,
        actions: FormikHelpers<CreateContactMutationInput>,
    ) => {
        const {data} = await createContact({
            variables: {input: values},
        });
        if (data?.createContact?.errors?.length === 0 && data.createContact.contact) {
            setFieldValue(contactFieldName, data.createContact.contact.id, true);
            enqueueSnackbar(t`Contact created!`, {variant: 'success'});
            toggle();
        } else {
            setErrors(data?.createContact?.errors, actions);
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            onClose={toggle}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container={true} justifyContent='flex-end'>
                    <IconButton onClick={toggle} size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.dialogContent} item={true} xs={12}>
                    {queryError && <GraphQLErrors error={queryError} refetch={refetch} />}
                    {loading && <Grid container justifyContent='center'><CircularProgress color='primary' /></Grid>}
                    {accountId && !loading && (
                        <ContactForm
                            initialValues={{
                                account: accountId,
                                ...(contactData && edit && {
                                    ...initialValues,
                                    email: contactData.contact.email,
                                    externalId: contactData.contact.externalId,
                                    firstName: contactData.contact.firstName,
                                    lastName: contactData.contact.lastName,
                                    mobilePhone: contactData.contact.mobilePhone,
                                    phone: contactData.contact.phone,
                                    type: contactData.contact.type.id,
                                    account: contactData.contact.account.id,
                                    id: contactData.contact.id,
                                } || initialValues)
                            }}
                            onSubmit={handleSubmit}
                            onCancel={toggle}
                            validate={validate}
                            initialStatus={initialStatus}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: 0,
    },

    dialogContent: {
        padding: theme.spacing(1),
    }
}));
